<script setup lang="ts">
import { ref, Ref, watch } from 'vue'
import { TBillAccount } from '@/types'
import ListAccountTableItem from '@/views/Reportings/Components/ListAccountTableItem.vue'
import { UTable, UInput, UISelect, UDateRange, UButton } from 'unit-uikit'
import { useFinancesStore } from '@/stores/finances'
import debounce from '@/utils/debounce'
import { ETransactionsStatus } from '@/types/api-values'
import { TRANSACTION_STATUS } from '@/utils/consts'
import moment from 'moment'

const { fetchListAccounts } = useFinancesStore()
const loading = ref(true)

const scrollData = {
  count: 0,
  requestCount: 0,
  size: 20,
  page: 1
}

const searchBillId = ref('')
const searchId = ref('')
const searchCompany = ref('')
const searchFio = ref('')
const searchPHone = ref('')
const searchStatus = ref('all')
const startDate = ref('')
const endDate = ref('')

const formatDateForQueryString = (date: any) => {
  const formatedDate = date.split('.')
  return `${formatedDate[0]}-${formatedDate[1]}-${formatedDate[2]}`
}

const statusOptions = [
  {
    id: 'all',
    name: 'Все'
  },
  {
    id: ETransactionsStatus.awaiting,
    name: TRANSACTION_STATUS[ETransactionsStatus.awaiting]
  },
  {
    id: ETransactionsStatus.paid,
    name: TRANSACTION_STATUS[ETransactionsStatus.paid]
  },
  {
    id: ETransactionsStatus.error,
    name: TRANSACTION_STATUS[ETransactionsStatus.error]
  },
  {
    id: ETransactionsStatus.rejected,
    name: TRANSACTION_STATUS[ETransactionsStatus.rejected]
  },
  {
    id: ETransactionsStatus.cancelled,
    name: TRANSACTION_STATUS[ETransactionsStatus.cancelled]
  },
  {
    id: ETransactionsStatus.sent,
    name: TRANSACTION_STATUS[ETransactionsStatus.sent]
  }
]

const listAccounts = ref([]) as Ref<TBillAccount[]>

fetchListAccounts(`?page=${scrollData.page}&count=${scrollData.size}`)
  .then((data: any) => {
    scrollData.count = data.count
    const result = data.results
    listAccounts.value.push(...result)
  })
  .catch(() => {
    console.log('error fetch list accounts')
  })
  .finally(() => {
    loading.value = false
  })

const loadAccounts = async () => {
  if (listAccounts.value.length < scrollData.count) {
    let queryParams = ''

    if (searchBillId.value) {
      queryParams += `&id=${searchBillId.value}`

      if (listAccounts.value.length && !(listAccounts.value.length < scrollData.requestCount)) {
        return
      }
    }

    if (searchId.value) {
      queryParams += `&uuid=${searchId.value}`

      if (listAccounts.value.length && !(listAccounts.value.length < scrollData.requestCount)) {
        return
      }
    }
    if (searchCompany.value) {
      queryParams += `&company=${searchCompany.value}`

      if (listAccounts.value.length && !(listAccounts.value.length < scrollData.requestCount)) {
        return
      }
    }
    if (searchPHone.value) {
      queryParams += `&phone=${searchPHone.value}`

      if (listAccounts.value.length && !(listAccounts.value.length < scrollData.requestCount)) {
        return
      }
    }
    if (searchFio.value) {
      queryParams += `&worker=${searchFio.value}`

      if (listAccounts.value.length && !(listAccounts.value.length < scrollData.requestCount)) {
        return
      }
    }
    if (searchStatus.value && searchStatus.value !== 'all') {
      queryParams += `&status=${searchStatus.value}`

      if (listAccounts.value.length && !(listAccounts.value.length < scrollData.requestCount)) {
        return
      }
    }

    if (startDate.value) {
      const formattedDate = moment(startDate.value).format('YYYY.MM.DD')
      queryParams += `&date_start=${formatDateForQueryString(formattedDate)}`

      if (listAccounts.value.length && !(listAccounts.value.length < scrollData.requestCount)) {
        return
      }
    }

    if (endDate.value) {
      const formattedDate = moment(endDate.value).format('YYYY.MM.DD')
      queryParams += `&date_end=${formatDateForQueryString(formattedDate)}`

      if (listAccounts.value.length && !(listAccounts.value.length < scrollData.requestCount)) {
        return
      }
    }

    scrollData.page++
    const queryString = `?page=${scrollData.page}&page_size=${scrollData.size}` + queryParams
    const data = await fetchListAccounts(queryString)
    if (data.results && data.count) {
      scrollData.requestCount = data.count
      const result = data.results
      listAccounts.value.push(...result)
    }
  }
}

const debounceLoadAccountsWithParams = debounce(() => {
  loadAccountsWithParams()
})

const loadAccountsWithParams = () => {
  scrollData.page = 0
  listAccounts.value = []
  loadAccounts()
}

const clearFilters = () => {
  searchBillId.value = ''
  searchId.value = ''
  searchCompany.value = ''
  searchFio.value = ''
  searchPHone.value = ''
  searchStatus.value = 'all'

  const startDateInput = document.getElementById('startDate') as HTMLInputElement
  const endDateInput = document.getElementById('endDate') as HTMLInputElement
  startDateInput.value = ''
  endDateInput.value = ''

  loadAccountsWithParams()
}

watch([searchBillId, searchId, searchCompany, searchFio, searchPHone, searchStatus, startDate, endDate], () => {
  debounceLoadAccountsWithParams()
})
</script>

<template>
  <div class="flex flex-col gap-2 mb-2">
    <div class="flex gap-3">
      <div class="flex-grow basis-1/3 flex gap-3">
        <UInput class="flex-grow" name="search" searchInput placeholder="Номер счета" :value="searchBillId" v-model="searchBillId" />
        <UDateRange
          class="!min-w-[210px] !max-w-[210px]"
          :start="startDate"
          :finish="endDate"
          @update-start-date="(date: string) => { startDate = date }"
          @update-finish-date="(date: string) => { endDate = date }"
          title="Дата выставления счета"
          startPlaceHolder="От"
          endPlaceHolder="До"
        />
        <UISelect class="flex-grow" label="Статус" :value="searchStatus" v-model="searchStatus" :options="statusOptions" />
        <UInput class="flex-grow" name="search" searchInput placeholder="UUID" :value="searchId" v-model="searchId" />
      </div>
    </div>
    <div class="flex gap-3">
      <UInput
        class="basis-1/6 !max-w-none flex-grow"
        name="search"
        searchInput
        placeholder="Название компании"
        :value="searchCompany"
        v-model="searchCompany"
      />
      <UInput
        class="basis-1/6 !max-w-none flex-grow"
        name="search"
        searchInput
        placeholder="ФИО исполнителя"
        :value="searchFio"
        v-model="searchFio"
      />
      <UInput
        class="basis-1/6 !max-w-none flex-grow"
        name="search"
        searchInput
        placeholder="Телефон исполнителя"
        :value="searchPHone"
        v-model="searchPHone"
      />
      <UButton size="sm" label="&#10006;" color="secondary" @click="clearFilters" />
    </div>
  </div>
  <UTable v-if="!loading && listAccounts.length" colspan="5" :callback="loadAccounts">
    <template #thead>
      <tr>
        <th>Номер счёта</th>
        <th>Компании</th>
        <th>Исполнитель</th>
        <th>Статус</th>
        <th>UUID</th>
      </tr>
    </template>
    <template #tbody>
      <ListAccountTableItem v-for="item in listAccounts" :key="item.id" :account="item" />
    </template>
  </UTable>
  <span class="bg-white p-4 rounded-lg" v-else-if="!loading && !listAccounts.length">Нет данных</span>
  <span class="bg-white p-4 rounded-lg" v-else-if="loading && !listAccounts.length">Загружается</span>
</template>
