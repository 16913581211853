<template>
  <div>
    <main-page-header :title="title" class="page__title"></main-page-header>
  </div>

  <router-view></router-view>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'

@Options({
  name: 'orders',
  components: {
    MainPageHeader
  },
  data() {
    return {
      title: 'Реестр заказов',
      ready: false
    }
  }
})

export default class Orders extends Vue {}
</script>

<style lang="scss" scoped>
.disabled-link:hover {
  cursor: not-allowed;
}
</style>
