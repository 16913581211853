<template>
  <div class="overlay justify-center" v-if="show">
    <div class="modal">
      <button type="button" class="absolute modal-close-btn" @click="closeModal">+</button>
      <header>
        <h3 class="modal-title mb-4">{{ title }}</h3>
        <p class="modal-description">{{ message }}</p>
      </header>
      <div class="modal-content">
        <div class="space-y-5">
          <div>
            <UInput class="!max-w-none flex-grow" label="Номер телефона" :value="username" v-model="username" mask="phone">
              <template #icon>
                <p class="absolute top-full text-error z-[1] text-xs">{{ usernameError }}</p>
              </template>
            </UInput>
          </div>
          <UInput class="!max-w-none flex-grow" label="Фамилия" :value="last_name" v-model="last_name" />
          <UInput class="!max-w-none flex-grow" label="Имя" :value="first_name" v-model="first_name" />
          <UInput class="!max-w-none flex-grow" label="Отчество" :value="middle_name" v-model="middle_name" />
        </div>

        <div class="mt-5 flex justify-end">
          <UButton label="Отмена" color="secondary" class="mr-5" @click="closeModal" />
          <UButton :label="successBtnLabel" :disabled="addBtnDisabled && !loading" @click="addNewUser" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { UButton, UInput } from 'unit-uikit'

@Options({
  components: {
    UInput,
    UButton
  },
  data() {
    return {
      loading: false,
      username: '',
      usernameError: '',
      first_name: '',
      last_name: '',
      middle_name: ''
    }
  },
  props: {
    show: Boolean,
    title: String,
    message: String,
    successBtnLabel: {
      type: String,
      default: 'Отправить'
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    addNewUser() {
      this.loading = true

      this.usernameError = ''
      const username = this.username.replace(/-/g, '')

      this.$store
        .dispatch('companies/addCompanyCustomer', {
          username: username,
          first_name: this.first_name,
          last_name: this.last_name,
          middle_name: this.middle_name
        })
        .then(() => {
          this.loading = false
          this.$emit('successModal')
        })
        .catch((e: any) => {
          const data = e.response.data
          const errText = data.username && data.username.join(', ')
          this.loading = false

          if (errText) {
            this.usernameError = errText
          } else {
            this.$emit('successModal', e)
          }
        })
    }
  },
  computed: {
    addBtnDisabled() {
      return !(this.last_name && this.first_name && this.middle_name && this.username.length === 16)
    }
  }
})
export default class SuccessModal extends Vue {}
</script>

<style lang="postcss" scoped>
.modal {
  @apply relative bg-white py-4 md:py-5 px-[15px] md:px-[30px] rounded-xl;
  box-shadow: 0px 2px 8px rgba(7, 8, 54, 0.14);

  &-title {
    @apply font-bold text-lg leading-[21px] text-black;
  }

  &-content {
    @apply my-0 md:mt-5 md:mb-[15px];
  }
}

.modal-description {
  @apply text-sm leading-4 tracking-[0.5px] text-[#C6C6C6];
}

.modal-close-btn {
  right: 15px;
  top: 15px;
  line-height: 16px;
  transform: rotate(45deg);
  font-weight: 300;
  font-size: 36px;
  text-align: center;
  @apply md:hidden;
}
</style>
