<script setup lang="ts">
import { ref, Ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { UTabs, UInput } from 'unit-uikit'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import WarningProcuration from '@/components/Messages/WarningProcuration.vue'
import { EProcurationStatus, TUnitAgreement } from '@/interfaces/main'
import { compareDates } from './utils'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { EUserRoles } from '@/types'

import { formatToServerDate, formatDate } from '@/utils/helpers'

const store = useStore()
const authStore = useAuthStore()
const { userRole } = storeToRefs(authStore)
const title = ref('')
const offer = ref<string>()
const verified = ref(false)

const route = useRoute()
const id = route.params?.id as string

const procurationDays = ref(0)

const unitAgreement = ref({}) as Ref<TUnitAgreement>

if (id) {
  store
    .dispatch('companies/getEntityRecord', id)
    .then((res: any) => {
      const _title = res?.name
      verified.value = res?.verified || false
      title.value = _title
      offer.value = res?.offer_date ? formatDate(res.offer_date, 'fullYear') : ''
      unitAgreement.value.agreement_with_unit_number = res?.agreement_with_unit_number
      unitAgreement.value.agreement_with_unit_date = res?.agreement_with_unit_date
        ? formatDate(new Date(res?.agreement_with_unit_date), 'fullYear')
        : ''
    })
    .catch((err: any) => {
      console.error(err)
    })

  store
    .dispatch('companies/getCompanyProcurations', { id: id, status: `&status=${EProcurationStatus.active}` })
    .then((res: any) => {
      const allExpDates =
        res.results?.map((item: any) => {
          return item.procuration_exp_date
        }) || []
      allExpDates.find((d: string) => {
        const remainDays = compareDates(new Date(d), new Date())
        if (remainDays < 31 && remainDays > 0) {
          procurationDays.value = remainDays
          return true
        }
        return false
      })
    })
    .catch((err: any) => {
      console.error(err)
    })
}

const updateUnitAgreement = (number: boolean) => {
  const payload: { agreement_with_unit_number?: number | string; agreement_with_unit_date?: string | null } = {
    agreement_with_unit_number: 0,
    agreement_with_unit_date: ''
  }
  if (number) {
    payload.agreement_with_unit_number = unitAgreement.value.agreement_with_unit_number
    delete payload.agreement_with_unit_date
  } else {
    payload.agreement_with_unit_date = formatToServerDate(unitAgreement.value.agreement_with_unit_date)
    delete payload.agreement_with_unit_number
  }

  store.dispatch('companies/updateUNITAgreement', { id: id, payload: payload }).catch((err: any) => {
    console.error(err)
  })
}

const tabs = [
  {
    routeName: 'companies.info',
    label: 'Инфо'
  },
  {
    routeName: 'companies.accesses',
    label: 'Доступы'
  },
  {
    routeName: 'companies.procuration',
    label: 'Доверенности'
  },
  {
    routeName: 'companies.objects',
    label: 'Объекты'
  },
  {
    routeName: 'companies.agents',
    label: 'Доверенные лица'
  },
  {
    routeName: 'companies.workers',
    label: 'Исполнители'
  }
]

const showTabs = computed(() => {
  return route.name !== 'procuration.new'
})

const readonly = computed(() => {
  return userRole.value === EUserRoles.support
})
</script>

<template>
  <main-page-header :title="title" class="page__title" />

  <p class="mt-[-50px] mb-3">Акцепт оферты от {{ offer }}</p>

  <div class="flex gap-3 mb-11">
    <UInput
      @change="updateUnitAgreement(true)"
      large
      maxLength="100"
      class="!w-[262px]"
      label="Номер договора"
      placeholder="0000000000"
      :value="unitAgreement.agreement_with_unit_number"
      v-model="unitAgreement.agreement_with_unit_number"
      :readonly="readonly"
    >
      <template #icon>
        <img class="w-5 h-5 top-4 right-3 relative" src="/icons/edit.svg" alt="редактировать" />
      </template>
    </UInput>
    <UInput
      @change="updateUnitAgreement(false)"
      large
      class="!w-[262px]"
      label="Дата договора"
      placeholder="дд.мм.гггг"
      :value="unitAgreement.agreement_with_unit_date"
      v-model="unitAgreement.agreement_with_unit_date"
      mask="date"
      :readonly="readonly"
    >
      <template #icon>
        <img class="w-5 h-5 top-4 right-3 relative" src="/icons/edit.svg" alt="редактировать" />
      </template>
    </UInput>
  </div>

  <WarningProcuration v-if="showTabs && procurationDays" class="mb-5">
    <span class="mr-2">Доверенность на представителя компании заканчивается в ближайшие {{ procurationDays }} дней.</span>
  </WarningProcuration>

  <u-tabs v-if="showTabs" :tabs="tabs" />

  <router-view :readonly="readonly"></router-view>
</template>

<style lang="scss" scoped></style>
