<template>
  <Loader title="Данные загружаются, пожалуйста подождите" v-if="loading" />
  <div class="flex flex-wrap gap-3 mb-5">
    <UInput searchInput placeholder="Поиск по имени" :value="searchFirstName" v-model="searchFirstName" />
    <UInput searchInput placeholder="Поиск по фамилии" :value="searchLastName" v-model="searchLastName" />
    <UInput searchInput placeholder="Поиск по телефону" :value="searchPhone" v-model="searchPhone" />
    <UInput searchInput placeholder="Поиск по ИНН" :value="searchINN" v-model="searchINN" />
    <UInput searchInput placeholder="Поиск по компании" :value="searchByCompany" v-model="searchByCompany" />
    <UISelect
      class="form-select mr-4 min-w-[180px]"
      label="Гражданство"
      unselectedLabel="Выберите гражданство"
      v-model="selectedCitizenship"
      :value="selectedCitizenship"
      :options="citizenshipData"
    />
    <div>
      <div class="flex">
        <UButton label="Поиск" size="sm" class="mr-2" @click="createFilterString" />
        <UButton label="&#10006;" size="sm" color="secondary" @click="clearFilters" />
      </div>
    </div>
  </div>

  <UTable v-if="!loading" colspan="4" :callback="loadWorkersOnScroll">
    <template #thead>
      <tr>
        <th class="w-[300px]">Исполнитель</th>
        <th class="w-[100px]">Гражданство</th>
        <th class="w-[100px]">
          <div class="flex gap-1">
            <span>Дата обновления</span>
            <SortItem :sortState="sortingDateCreated" @sort="sortWorkers($event, 'dateCreated')" />
          </div>
        </th>
        <th class="w-[140px]">
          <div class="flex gap-1">
            <span>Статус данных</span>
            <SortItem :sortState="sortingCompleteData" @sort="sortWorkers($event, 'completeData')" />
          </div>
        </th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="worker in workers" :key="worker.id" @click="$router.push('/worker/' + worker.id)">
        <td>
          <div class="flex items-center h-full">
            <img class="static mr-1" src="/icons/icon-user.svg" alt="user-avatar" />
            <p class="font-bold">{{ getFullName(worker) }}</p>
          </div>
        </td>
        <td>
          <p>{{ getCitizenship(worker.personaldata?.citizenship) || '' }}</p>
        </td>
        <td>
          <p class="date">{{ worker.personaldata?.last_updated ? formatDate(worker.personaldata?.last_updated) : '' }}</p>
        </td>
        <td>
          <p class="status">{{ getUpdateStatus(worker) }}</p>
        </td>
        <!-- <td>
              <button type="button" class="edit-btn relative">
                <img class="" src="/icons/extra.svg" alt="Редактировать" />
              </button>
            </td> -->
      </tr>
    </template>
  </UTable>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { UInput, UButton, UISelect, UTable } from 'unit-uikit'
import { formatDate } from '@/utils/helpers'
import Loader from '@/components/Loader/Loader.vue'
import COUNTRIES from '@/utils/Countries'
import { SERVER_COUNTRIES } from '@/utils/consts'
import SortItem from '@/components/Sort/SortItem.vue'

@Options({
  name: 'VerifiedWorkers',
  components: {
    UTable,
    UInput,
    UButton,
    UISelect,
    Loader,
    SortItem
  },
  data() {
    return {
      citizenshipData: [
        {
          id: SERVER_COUNTRIES.RUSSIA,
          name: COUNTRIES[SERVER_COUNTRIES.RUSSIA]
        },
        {
          id: SERVER_COUNTRIES.BELARUS,
          name: COUNTRIES[SERVER_COUNTRIES.BELARUS]
        },
        {
          id: SERVER_COUNTRIES.ARMENIA,
          name: COUNTRIES[SERVER_COUNTRIES.ARMENIA]
        },
        {
          id: SERVER_COUNTRIES.KAZAKHSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KAZAKHSTAN]
        },
        {
          id: SERVER_COUNTRIES.KYRGYZSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KYRGYZSTAN]
        },
        {
          id: SERVER_COUNTRIES.UKRAINE,
          name: COUNTRIES[SERVER_COUNTRIES.UKRAINE]
        },
        {
          id: SERVER_COUNTRIES.DNR,
          name: COUNTRIES[SERVER_COUNTRIES.DNR]
        },
        {
          id: SERVER_COUNTRIES.LNR,
          name: COUNTRIES[SERVER_COUNTRIES.LNR]
        }
      ],
      filterString: '',
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      loading: true,
      selectedCitizenship: '',
      workers: [],
      searchFirstName: '',
      searchLastName: '',
      searchPhone: '',
      searchINN: '',
      searchByCompany: '',
      sortingDateCreated: null, // null, true, false
      sortingCompleteData: null // null, true, false
    }
  },
  created() {
    this.loadContent(false)
  },
  methods: {
    formatDate,
    sortWorkers(state: any, type: string) {
      if (type === 'dateCreated') {
        this.sortingCompleteData = null
        this.sortingDateCreated = state
        this.createFilterString()
        return
      }
      if (type === 'completeData') {
        this.sortingDateCreated = null
        this.sortingCompleteData = state
        this.createFilterString()
      }
    },
    loadWorkersWithParams() {
      this.scrollData.page = 0
      this.scrollData.count = 1
      this.workers = []
      this.loadWorkersOnScroll()
    },
    getFullName(worker: any) {
      return `${worker.last_name || ''} ${worker.first_name || ''} ${worker.middle_name || ''}`
    },
    getCitizenship(citizenship: string) {
      return COUNTRIES[citizenship]
    },
    getUpdateStatus(worker: any) {
      if (worker.complete_data) {
        return 'Полные данные'
      } else {
        return 'Неполные данные'
      }
    },
    createFilterString() {
      let queryParams = ''

      if (this.sortingDateCreated !== null) {
        queryParams = `&sorting=${this.sortingDateCreated ? '' : '-'}personaldata__last_updated`
      }
      if (this.sortingCompleteData !== null) {
        queryParams = `&sorting=${this.sortingCompleteData ? '' : '-'}complete_data`
      }

      if (this.searchFirstName) {
        queryParams += `&first_name=${this.searchFirstName}`
      }
      if (this.searchLastName) {
        queryParams += `&last_name=${this.searchLastName}`
      }
      if (this.searchPhone) {
        queryParams += `&phone=${this.searchPhone.replaceAll('-', '')}`
      }
      if (this.searchINN) {
        queryParams += `&inn=${this.searchINN}`
      }
      if (this.searchByCompany) {
        queryParams += `&company=${this.searchByCompany}`
      }
      if (this.selectedCitizenship) {
        queryParams += `&citizenship=${this.selectedCitizenship}`
      }

      this.filterString = queryParams
      this.loadWorkersWithParams()
    },
    clearFilters() {
      this.searchFirstName = ''
      this.searchLastName = ''
      this.searchPhone = ''
      this.searchINN = ''
      this.searchByCompany = ''
      this.selectedCitizenship = ''
      this.filterString = ''
      this.loadWorkersWithParams()
    },
    async loadWorkersOnScroll() {
      if (this.workers.length < this.scrollData.count) {
        this.scrollData.page++

        const response = await this.$store.dispatch(
          'worker/getSupportWorker',
          `?page=${this.scrollData.page}&count=${this.scrollData.size}&verified=${
            this.$route.name === 'workers.verified' ? 'True' : 'False'
          }` + this.filterString
        )

        const data = response
        if (data.results && data.count) {
          this.scrollData.count = data.count
          const workers = data.results
          this.workers.push(...workers)
        }
      }
    },
    loadContent(isTabChange: boolean) {
      this.loading = true

      if (isTabChange) {
        this.scrollData.page = 1
      }

      let queryParams = ''

      if (this.sortingDateCreated !== null) {
        queryParams = `&sorting=${this.sortingDateCreated ? '' : '-'}personaldata__last_updated`
      }
      if (this.sortingCompleteData !== null) {
        queryParams = `&sorting=${this.sortingCompleteData ? '' : '-'}complete_data`
      }

      this.$store
        .dispatch(
          'worker/getSupportWorker',
          `?page=${this.scrollData.page}&count=${this.scrollData.size}&verified=${
            this.$route.name === 'workers.verified' ? 'True' : 'False'
          }` + queryParams
        )
        .then((res: any) => {
          this.scrollData.count = res.count
          this.workers = res.results
          this.loading = false
        })
        .catch((e: any) => {
          this.loading = false
          console.error(e)
        })
    }
  },
  watch: {
    $route(to, from) {
      this.loadContent(true)
    }
  }
})
export default class WorkersList extends Vue {}
</script>

<style lang="postcss" scoped>
:deep(table) {
  @apply table-fixed;
}

td {
  word-break: break-word;
  @apply overflow-hidden text-ellipsis text-black max-w-[404px];
}
tr:hover td {
  @apply bg-gray-100 cursor-pointer;
}
</style>
