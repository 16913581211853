<template>
  <div class="worker-info overflow-x-hidden">
    <div class="worker-info__main" v-if="worker">
      <div class="worker-info__main--inner gap-4 flex flex-col">
        <h1 class="heading-black-lg">Гражданство и контактные данные</h1>
        <div class="flex gap-4">
          <UISelect
            class="flex-grow"
            label="Гражданство"
            unselectedLabel="Выберите гражданство"
            :value="worker.personaldata.citizenship"
            v-model="worker.personaldata.citizenship"
            :options="citizenshipData"
            @change="updateWorkerInfo('citizenship')"
          />
          <UInput
            v-if="worker.passport_information.length"
            class="flex-grow"
            label="Тип паспорта"
            :value="worker.passport_information[0].type"
            v-model="worker.passport_information[0].type"
            :disabled="true"
          />
        </div>
        <div class="flex gap-4">
          <UInput 
            class="flex-grow" 
            label="Email" :value="worker.email" 
            v-model="worker.email" 
            @keypress.enter="updateWorkerInfo('email')"
            @blurAction="updateWorkerInfo('email')"
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ emailError }}</p>
            </template>
          </UInput>
          <div class="flex-grow" @click="changePhone">
            <UInput
              class="w-full"
              label="Мобильный телефон"
              :value="worker.username"
              v-model="worker.username"
              mask="phone"
              :readonly="true"
            />
          </div>
        </div>
        <div class="flex gap-4">
          <UISelect
            class="flex-grow basis-1/3"
            label="Регион работы"
            unselectedLabel="Выберите регион работы"
            :value="worker.region"
            v-model="worker.region"
            :options="regionOptions"
            @change="updateWorkerInfo('region')"
          />
          <UISelect
            class="flex-grow basis-1/3"
            label="Специальность"
            unselectedLabel="Выберите специальность"
            :value="worker.specialization"
            v-model="worker.specialization"
            :options="specializationOptions"
            @change="updateWorkerInfo('specialization')"
          />
        </div>
        <h1 class="heading-black-lg">Паспортные данные</h1>
        <div class="flex gap-4">
          <UInput 
            class="flex-grow"
            label="Фамилия" 
            :value="worker.last_name" 
            v-model="worker.last_name"
            @keypress.enter="updateWorkerInfo('last_name')"
            @blurAction="updateWorkerInfo('last_name')"
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ last_nameError }}</p>
            </template>
          </UInput>
          <UInput 
            class="flex-grow" 
            label="Имя" 
            :value="worker.first_name" 
            v-model="worker.first_name"
            @keypress.enter="updateWorkerInfo('first_name')"
            @blurAction="updateWorkerInfo('first_name')" 
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ first_nameError }}</p>
            </template>
          </UInput>
        </div>
        <div class="flex gap-4">
          <UInput 
            class="basis-1/3 flex-grow" 
            label="Отчество" 
            :value="worker.middle_name" 
            v-model="worker.middle_name"  
            @keypress.enter="updateWorkerInfo('middle_name')"
            @blurAction="updateWorkerInfo('middle_name')" 
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ middle_nameError }}</p>
            </template>
          </UInput>
          <UISelect
            @change="updateWorkerInfo('sex')"
            class="basis-1/3 flex-grow"
            label="Пол"
            unselectedLabel="Выберите пол"
            :value="worker.personaldata.sex"
            v-model="worker.personaldata.sex"
            :options="genderOptions"
          />
        </div>
        <div v-if="worker.passport_information.length" class="flex gap-4">
          <UInput
            class="flex-grow"
            label="Серия"
            :value="worker.passport_information[0].passport_series"
            v-model="worker.passport_information[0].passport_series"
            @keypress.enter="updateWorkerInfo('passport_series')"
            @blurAction="updateWorkerInfo('passport_series')" 
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ passport_seriesError }}</p>
            </template>
          </UInput>
          <UInput
            class="flex-grow"
            label="Номер"
            :value="worker.passport_information[0].passport_number"
            v-model="worker.passport_information[0].passport_number"
            @keypress.enter="updateWorkerInfo('passport_number')"
            @blurAction="updateWorkerInfo('passport_number')" 
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ passport_numberError }}</p>
            </template>
          </UInput>
        </div>
        <div v-if="worker.passport_information.length" class="flex gap-4">
          <UInput
            class="flex-grow"
            label="Кем выдан"
            :value="worker.passport_information[0].passport_whom_given"
            v-model="worker.passport_information[0].passport_whom_given"
            :title="worker.passport_information[0].passport_whom_given"
            @keypress.enter="updateWorkerInfo('passport_whom_given')"
            @blurAction="updateWorkerInfo('passport_whom_given')" 
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ passport_whom_givenError }}</p>
            </template>
          </UInput>
          <UInput
            class="flex-grow"
            label="Идентификационный номер"
            :value="worker.passport_information[0].passport_id_number"
            v-model="worker.passport_information[0].passport_id_number"
            @keypress.enter="updateWorkerInfo('passport_id_number')"
            @blurAction="updateWorkerInfo('passport_id_number')" 
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ passport_id_numberError }}</p>
            </template>
          </UInput>
        </div>
        <div v-if="worker.passport_information.length" class="flex gap-4">
          <UInput
            class="flex-grow"
            label="Дата выдачи паспорта"
            mask="date"
            :value="worker.passport_information[0].date_of_issue"
            v-model="worker.passport_information[0].date_of_issue"
            @keypress.enter="updateWorkerInfo('date_of_issue')"
            @blurAction="updateWorkerInfo('date_of_issue')"
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ date_of_issueError }}</p>
            </template>
          </UInput>
          <UInput
            class="flex-grow"
            label="Дата окончания срока действия"
            mask="date"
            :value="worker.passport_information[0].passport_expires"
            v-model="worker.passport_information[0].passport_expires"
            @keypress.enter="updateWorkerInfo('passport_expires')"
            @blurAction="updateWorkerInfo('passport_expires')"
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ passport_expiresError }}</p>
            </template>
          </UInput>
        </div>
        <div v-if="worker.passport_information.length" class="flex gap-4">
          <UInput
            class="flex-grow"
            label="Место рождения"
            :value="worker.passport_information[0].place_of_birth"
            v-model="worker.passport_information[0].place_of_birth"
            :title="worker.passport_information[0].place_of_birth"
            @keypress.enter="updateWorkerInfo('place_of_birth')"
            @blurAction="updateWorkerInfo('place_of_birth')"
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ place_of_birthError }}</p>
            </template>
          </UInput>
          <UInput
            v-if="worker.personaldata"
            class="flex-grow"
            label="Дата рождения"
            mask="date"
            :value="worker.personaldata.date_of_birth"
            v-model="worker.personaldata.date_of_birth"
            @keypress.enter="updateWorkerInfo('date_of_birth')"
            @blurAction="updateWorkerInfo('date_of_birth')"
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ date_of_birthError }}</p>
            </template>
          </UInput>
        </div>
        <div class="flex w-1/2">
          <UInput
            v-if="worker.personaldata"
            class="flex-grow"
            label="Индекс"
            :value="worker.personaldata.index"
            v-model="worker.personaldata.index"
            @keypress.enter="updateWorkerInfo('index')"
            @blurAction="updateWorkerInfo('index')"
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ indexError }}</p>
            </template>
          </UInput>
        </div>
        <div v-if="worker.passport_information.length" class="flex">
          <UInput
            class="flex-grow"
            label="Адрес прописки"
            :value="worker.passport_information[0].registered_address"
            v-model="worker.passport_information[0].registered_address"
            :title="worker.passport_information[0].registered_address"
            @keypress.enter="updateWorkerInfo('registered_address')"
            @blurAction="updateWorkerInfo('registered_address')"
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ registered_addressError }}</p>
            </template>
          </UInput>
        </div>
        <h1 class="heading-black-lg">Банковские реквизиты</h1>
        <div class="flex gap-4">
          <UInput
            v-if="worker.personaldata"
            class="flex-grow"
            label="ИНН самозанятого"
            :value="worker.personaldata.inn"
            v-model="worker.personaldata.inn"
            @keypress.enter="updateWorkerInfo('inn')"
            @blurAction="updateWorkerInfo('inn')"
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ innError }}</p>
            </template>
          </UInput>
          <UInput
            v-if="worker.personaldata"
            class="flex-grow"
            label="Номер банковской карты"
            :value="worker.personaldata.card_number"
            v-model="worker.personaldata.card_number"
            @keypress.enter="updateWorkerInfo('card_number')"
            @blurAction="updateWorkerInfo('card_number')"
          >
            <template #icon>
              <p class="absolute top-full text-error text-xs">{{ card_numberError }}</p>
            </template>
          </UInput>
        </div>
        <h1 class="heading-black-lg">Статусы</h1>
        <div class="flex gap-4">
          <div class="status-bar flex flex-grow">
            <p class="status-bar__text">Статус СМЗ</p>
            <img clas="status-bar__icon" :src="getStatusSrc('smz')" alt="ok" />
          </div>
          <div class="status-bar flex flex-grow">
            <p class="status-bar__text">Статус полноты данных</p>
            <img clas="status-bar__icon" :src="getStatusSrc('completedata')" alt="ok" />
          </div>
        </div>
        <div class="flex gap-4">
          <div class="status-bar flex flex-grow">
            <p class="status-bar__text">Действующий договор</p>
            <img clas="status-bar__icon" :src="getStatusSrc('agreement')" alt="ok" />
          </div>
          <div class="status-bar flex flex-grow">
            <p class="status-bar__text">Реквизиты оплаты</p>
            <img clas="status-bar__icon" :src="getStatusSrc('payment')" alt="ok" />
          </div>
        </div>
      </div>
      <div class="worker-info__main--inner">
        <h1 class="heading-black-lg mb-4">Паспорт ({{ getPhotoTitle }})</h1>
        <div class="relative font-medium text-xl">
          <img class="passport-img mb-4" :src="mainPhoto" alt="passport" />
        </div>
        <UButton class="mb-2 px-3" label="Поменять фото" @click="selectFile" />
        <input type="file" name="passportNewDOc" id="passportNewDOc" ref="passportNewDOcRef" class="hidden" @change="updatePhoto" />
        <div class="controls flex">
          <div
            v-if="worker.passport_information.length"
            class="img-switcher"
            :class="{ 'img-switcher--active': galleryTab === 1 }"
            @click="changeGalleryTab(1)"
          >
            <img class="controls-img" :src="getPhotoSrc(worker.passport_information[0].passport_photo_reversal)" alt="passport" />
          </div>
          <div
            v-if="worker.passport_information.length"
            class="img-switcher"
            :class="{ 'img-switcher--active': galleryTab === 2 }"
            @click="changeGalleryTab(2)"
          >
            <img class="controls-img" :src="getPhotoSrc(worker.passport_information[0].passport_photo_registered_address)" alt="passport" />
          </div>
          <div
            v-if="worker.passport_information.length"
            class="img-switcher"
            :class="{ 'img-switcher--active': galleryTab === 3 }"
            @click="changeGalleryTab(3)"
          >
            <img
              class="controls-img"
              :src="getPhotoSrc(worker.passport_information[0].selfie ? worker.passport_information[0].selfie : '')"
              alt="passport"
            />
          </div>
          <div class="relative flex">
            <UInput
              v-if="worker.personaldata"
              class="flex-grow mr-4 max-w-[278px]"
              :label="getVerificationStatus"
              :value="getVerificationStatus"
              :disabled="true"
            />
            <USwitch
              class="!absolute right-3 !bg-[#526581]/0 border-0"
              labelON=""
              labelOFF=""
              v-if="worker.personaldata"
              v-model="isVerified"
              @click="isVerified = !isVerified"
              :disabled="isVerified"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <ChangePhoneModal
    :show="showModal"
    :phone="worker?.username"
    title="Изменить номер телефона"
    @closeModal="showModal = false"
    @successModal="changePhoneFinish"
  />

</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { UInput, UButton, USwitch, UISelect } from 'unit-uikit'
import { formatDate } from '@/utils/helpers'
import { regions } from '@/utils/regions'
import ChangePhoneModal from '@/components/Modals/ChangePhoneModal.vue'
import { jsonToFormData } from '@/utils/jsonToForm'
import { useWorkersStore } from '@/stores/workers'
import COUNTRIES from '@/utils/Countries'
import { SERVER_COUNTRIES } from '@/utils/consts'

function normalizeData(value: any, key: string) {
  switch (key) {
    case 'date_of_issue':
    case 'date_of_birth':
    case 'passport_expires':
      if (value) {
        const [day, month, year] = value.split('.')
        return `${year}-${month}-${day}`
      }
      return ''
    default:
      return value
  }
}

@Options({
  name: 'WorkerInfo',
  components: {
    UInput,
    UButton,
    USwitch,
    ChangePhoneModal,
    UISelect
  },
  data() {
    return {
      workerStore: useWorkersStore(),
      mediaURL: process.env.VUE_APP_URL + '/media/',
      worker: null,
      isCompleteData: true,
      galleryTab: 1,
      isVerified: false,
      verificationStatus: null,
      showModal: false,
      specializationOptions: [],
      citizenshipData: [
        {
          id: SERVER_COUNTRIES.RUSSIA,
          name: COUNTRIES[SERVER_COUNTRIES.RUSSIA]
        },
        {
          id: SERVER_COUNTRIES.BELARUS,
          name: COUNTRIES[SERVER_COUNTRIES.BELARUS]
        },
        {
          id: SERVER_COUNTRIES.ARMENIA,
          name: COUNTRIES[SERVER_COUNTRIES.ARMENIA]
        },
        {
          id: SERVER_COUNTRIES.KAZAKHSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KAZAKHSTAN]
        },
        {
          id: SERVER_COUNTRIES.KYRGYZSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KYRGYZSTAN]
        },
        {
          id: SERVER_COUNTRIES.UKRAINE,
          name: COUNTRIES[SERVER_COUNTRIES.UKRAINE]
        },
        {
          id: SERVER_COUNTRIES.DNR,
          name: COUNTRIES[SERVER_COUNTRIES.DNR]
        },
        {
          id: SERVER_COUNTRIES.LNR,
          name: COUNTRIES[SERVER_COUNTRIES.LNR]
        }
      ],
      genderOptions: [
        {
          id: 'M',
          name: 'Мужской'
        },
        {
          id: 'F',
          name: 'Женский'
        }
      ],
      emailError: '',
      last_nameError: '',
      first_nameError: '',
      middle_nameError: '',
      passport_seriesError: '',
      passport_numberError: '',
      passport_whom_givenError: '',
      passport_id_numberError: '',
      date_of_issueError: '',
      passport_expiresError: '',
      date_of_birthError: '',
      indexError: '',
      registered_addressError: '',
      innError: '',
      card_numberError: '',
      place_of_birthError: ''
    }
  },
  methods: {
    getWorkerFieldValue(key: string) {
      switch (key) {
        case 'passport_number':
        case 'passport_id_number':
        case 'passport_series':
        case 'passport_whom_given':
        case 'date_of_issue':
        case 'registered_address':
        case 'passport_expires':
        case 'place_of_birth':
          return this.worker.passport_information[0][key]
        case 'index':
        case 'sex':
        case 'citizenship':
        case 'date_of_birth':
        case 'inn':
        case 'card_number':
          return this.worker.personaldata[key]
        default:
          return this.worker[key]
      }
    },
    updateWorkerInfo(field: string) {
      const id = this.$route?.params?.id

      const data = {
        [field]: normalizeData(this.getWorkerFieldValue(field), field)
      }

      return this.workerStore.updateWorker(id, data)
        .then(() => {
          this[field + 'Error'] = ''
        })
        .catch((data: any) => {
          const errText = data[field]
          this[field + 'Error'] = errText && errText.join(', ')
        })
    },
    selectFile() {
      this.$refs.passportNewDOcRef.click()
    },
    updatePhoto($event: any) {
      this.errors = []
      const file = $event.target.files && $event.target.files[0]
      // const fileSize = file ? file.size / 1024 / 1024 : 0
      // if (fileSize > 32) {
      //   this.errors.push('Размер файла не должен превышать 32МБ')
      // }

      let photo = ''
      switch (this.galleryTab) {
        case 1:
          photo = 'passport_photo_reversal'
          break
        case 2:
          photo = 'passport_photo_registered_address'
          break
        case 3:
          photo = 'selfie'
          break
        default:
          photo = 'file'
      }

      const data = {
        [photo]: file
      }

      const bodyFormData = jsonToFormData(data)

      this.$store
        .dispatch('worker/changeWorkerVerificationStatus', { id: this.$route.params.id, data: bodyFormData })
        .then(() => {
          this.getSingleWorkerData()
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    changePhone() {
      this.showModal = true
    },
    changePhoneFinish() {
      this.getSingleWorkerData()
      this.showModal = false
    },
    formatDate,
    async getSingleWorkerData() {
      const id = this.$route.params.id
      if (id) {
        this.worker = await this.workerStore.fetchWorker(id)
        if (this.isVerified !== this.worker.verified) {
          this.isVerified = this.worker.verified
        }
      }
    },
    changeGalleryTab(tab: number) {
      this.galleryTab = tab
    },
    getPhotoSrc(link: string) {
      if (link.length === 0) return '/img/icons/passport_empty.png'
      return this.mediaURL + link
    },
    getStatusSrc(type: string) {
      const okIcon = '/icons/success.svg'
      const notIcon = '/icons/mistake.svg'

      switch (type) {
        case 'agreement':
          return this.worker.current_agreement ? okIcon : notIcon
        case 'smz':
          return this.worker.personaldata?.is_self_employed ? okIcon : notIcon
        case 'payment':
          return this.worker.payment_details ? okIcon : notIcon
        case 'completedata':
          return this.worker.complete_data ? okIcon : notIcon

        default:
          return notIcon
      }
    },
  },
  computed: {
    regionOptions() {
      return regions?.map((item: any) => {
        return {
          id: item.geoname_name,
          name: item.name
        }
      }) || []
    },
    getPhotoTitle() {
      if (this.galleryTab === 1) {
        return 'Фото разворота паспорта'
      } else if (this.galleryTab === 2) {
        return 'Фото с регистрацией'
      } else {
        return 'Сэлфи'
      }
    },
    mainPhoto() {
      const emptyPhoto = '/img/icons/passport_empty.png'
      let photo = ''
      if (this.worker.passport_information.length) {
        switch (this.galleryTab) {
          case 1:
            photo = this.worker.passport_information[0].passport_photo_reversal
            return photo ? this.mediaURL + photo : emptyPhoto
          case 2:
            photo = this.worker.passport_information[0].passport_photo_registered_address
            return photo ? this.mediaURL + photo : emptyPhoto
          case 3:
            photo = this.worker.passport_information[0].selfie
            return photo ? this.mediaURL + photo : emptyPhoto

          default:
            return emptyPhoto
        }
      } else {
        return emptyPhoto
      }
    },
    getVerificationStatus() {
      return this.isVerified ? 'Верифицирован' : 'Не пройдена'
    }
  },
  created() {
    this.getSingleWorkerData()
    this.workerStore.fetchSpecializations()
      .then((data: {id: string, name: string}) => {
        this.specializationOptions = data
      })
  },
  watch: {
    isVerified(val) {
      this.$store
        .dispatch('worker/changeWorkerVerificationStatus', { id: this.$route.params.id, data: { verified: this.isVerified } })
        .then(() => {
          this.getSingleWorkerData()
        })
        .catch((e: any) => {
          console.error(e)
        })
    }
  }
})
export default class WorkerInfo extends Vue {}
</script>

<style lang="scss" scoped>
.status-bar {
  width: 200px;
  height: 44px;
  background: white;
  border-radius: 2px;
  padding: 17px 14px;
  justify-content: space-between;
  align-items: center;

  &__icon {
    width: 14px;
    height: 14px;
  }

  &__text {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.5px;
  }
}

.worker-info {
  &__main {
    display: flex;
    max-height: 100%;

    &--inner {
      width: 50%;

      > .passport-img {
        max-height: 500px;
      }

      &:last-child {
        padding: 0 5% 0 36px;
      }

      > .controls {
        display: flex;

        > .img-switcher {
          width: 37px;
          height: 48px;
          border: 3px solid #a8a8a9;
          box-sizing: border-box;
          border-radius: 5px;
          margin-right: 12px;

          &--active {
            border: 3px solid black;
          }

          > .controls-img {
            min-width: 30px;
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
            vertical-align: top;
            padding: 0;
          }
        }
      }
    }
  }
}
</style>
