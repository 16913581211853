<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import CompanyMainInfo from './CompanyInfoViews/CompanyMainInfo.vue'
import CompanyBankInfo from './CompanyInfoViews/CompanyBankInfo.vue'
import CompanyConditions from './CompanyInfoViews/CompanyConditions.vue'
import CompanyTemplates from './CompanyInfoViews/CompanyTemplates.vue'
import CompanyBudget from './CompanyInfoViews/CompanyBudget.vue'
import CompanyCheckList from './CompanyInfoViews/CompanyCheckList.vue'

const store = useStore()
const route = useRoute()

function normalizeEntityRecord(data: any) {
  const duration = data?.agreement_duration?.split(' ')

  return {
    main: {
      name: data.name,
      ogrn: data.ogrn,
      inn: data.inn,
      email: data.email,
      position: data.signer,
      phone: data?.phone,
      okved: data?.okved,
      kpp: data?.kpp,
      actual_address: data?.actual_address,
      registered_address: data?.registered_address,
      full_company_name: data?.full_company_name,
      verified: data?.verified,
      type: data?.type
    },
    bank: {
      bik: data.bank_account?.bik,
      bank: data.bank_account?.bank,
      account: data.bank_account?.checking_account,
      correspondent: data.bank_account?.correspondent_account,
      company: data.bank_account?.company
    },
    conditions: {
      monthly_limit_without_tax: data.conditions?.monthly_limit_without_tax,
      unitpay_commission: data.conditions?.unitpay_commission,
      monthly_limit_with_tax: data.conditions?.monthly_limit_with_tax,
      agreement_duration: (duration && duration[0]) || 0
    },
    templates: {
      agreement: data.templates?.agreement || [],
      task_order: data.templates?.task_order || [],
      acceptance_certificate: data.templates?.acceptance_certificate || []
    },
    budget: {
      deposite: data.conditions?.balance,
      reserve: data.conditions?.reserve,
      free: (data.conditions?.balance || 0) - (data.conditions?.reserve || 0)
    }
  }
}

const ready = ref(false)
const company = ref({})

const id = computed(() => {
  const _id = route?.params?.id as string
  return _id
})

const updateTemplates = () => {
  ready.value = false

  if (id.value) {
    store
      .dispatch('companies/getEntityRecord', id.value)
      .then((res: any) => {
        company.value = normalizeEntityRecord(res)
        ready.value = true
      })
      .catch((err: any) => {
        console.error(err)
        ready.value = true
      })
  }
}

if (id.value) {
  updateTemplates()
}

defineProps({
  readonly: {
    type: Boolean,
    default: true
  }
})
</script>

<template>
  <div class="flex flex-col overflow-auto grow" v-if="ready">
    <CompanyMainInfo :readonly="readonly" :info="company.main" />
    <CompanyBankInfo :readonly="readonly" :info="company.bank" />
    <CompanyConditions :readonly="readonly" :info="company.conditions" />
    <CompanyTemplates :readonly="readonly" :info="company.templates" @updateData="updateTemplates" />
    <CompanyBudget />
    <CompanyCheckList />
  </div>
</template>

<style lang="scss" scoped></style>
