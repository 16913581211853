<template>
  <h2 class="text-2xl font-bold mb-5">Шаблоны документации к заказу</h2>
  <div class="flex gap-4 mb-5">
    <div class="min-w-[200px] flex overflow-hidden">
      <TemplateItem
        title="Договор"
        @addNewDoc="openModal(null, ETemplateTypes.agreement)"
        @updateDoc="openModal($event, ETemplateTypes.agreement)"
        @deleteDoc="deleteTemplate($event)"
        :templates="agreement"
        :readonly="readonly"
      />
    </div>
    <div class="min-w-[200px] flex overflow-hidden">
      <TemplateItem
        title="Задание"
        @addNewDoc="openModal(null, ETemplateTypes.task)"
        @updateDoc="openModal($event, ETemplateTypes.task)"
        @deleteDoc="deleteTemplate($event)"
        :templates="task_order"
        :readonly="readonly"
      />
    </div>
    <div class="min-w-[200px] flex overflow-hidden">
      <TemplateItem
        title="Акт"
        @addNewDoc="openModal(null, ETemplateTypes.certificate)"
        @updateDoc="openModal($event, ETemplateTypes.certificate)"
        @deleteDoc="deleteTemplate($event)"
        :templates="acceptance_certificate"
        :readonly="readonly"
      />
    </div>
  </div>

  <template v-if="showNewDocModal">
    <NewTemplateModal
      :show="showNewDocModal"
      title="Загрузка шаблона для "
      :doc="updateDoc"
      :template="type"
      @closeModal="showNewDocModal = false"
      @successModal="handleFile"
    />
  </template>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

import { jsonToFormData } from '@/utils/jsonToForm'

import { API } from '@/utils/API'
import axios from 'axios'

import TemplateItem from '../Components/TemplateItem.vue'
import NewTemplateModal from '@/components/Modals/NewTemplateModal.vue'
import { ETemplateTypes } from '@/types/api-values'

@Options({
  name: 'CompanyTemplates',
  components: {
    TemplateItem,
    NewTemplateModal
  },
  data() {
    const info = this.info as any
    return {
      ETemplateTypes,
      showNewDocModal: false,
      updateDoc: null,
      type: ETemplateTypes.agreement,
      agreement: info?.agreement,
      task_order: info?.task_order,
      acceptance_certificate: info?.acceptance_certificate
    }
  },
  emits: ['updateData'],
  props: {
    info: { type: Object },
    readonly: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    openModal(doc: any, type: string) {
      this.updateDoc = doc
      this.type = type
      this.showNewDocModal = true
    },
    handleFile(data: any) {
      const docId = data.id
      const id = this.$route?.params?.id

      delete data.id
      const bodyFormData = jsonToFormData(data)

      const method = docId ? 'patch' : 'post'
      const endpoint = docId ? API.UPDATE_ENTITY_TEMPLATES : API.ADD_ENTITY_TEMPLATES

      return axios[method](endpoint(docId || id), bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(() => {
          this.showNewDocModal = false
          this.$emit('updateData')
        })
        .catch(() => {
          this.showNewDocModal = false
        })
    },
    deleteTemplate(doc: any) {
      axios
        .delete(API.REMOVE_TEMPLATE(doc.id))
        .then(() => {
          this.$emit('updateData')
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }
})
export default class CompanyTemplates extends Vue {}
</script>

<style lang="scss" scoped>
.plus-icon {
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 18px;
  font-size: 18px;
  margin-left: 25px;
  flex-shrink: 0;
}
</style>
