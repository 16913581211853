<template>
  <div class="worker-description">
    <h3 class="flex flex-col">
      <span class="mb-1">{{ getUserName(user) }}</span>
      <div class="flex flex-col text-xs text-gray-400">
        <span>{{ getUserPhone(user) }}</span>
        <span>{{ getUserINN(user) }}</span>
      </div>
    </h3>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    user: Object
  },
  methods: {
    getUserName(user: any) {
      return `${user.last_name || ''} ${user.first_name || ''} ${user.middle_name || ''}`
    },
    getUserPhone(user: any) {
      return `${user.username || ''}`
    },
    getUserINN(user: any) {
      return `${user.inn || ''}`
    }
  }
})
export default class UsersTableItem extends Vue {}
</script>
