<template>
  <div class="overlay" v-if="show">
    <div class="popup-container">
      <h4 class="popup-title">{{ title }}</h4>

      <div class="mb-5">
        <slot name="content" />
      </div>

      <div class="flex">
        <UButton :label="cancelBtn" @click.prevent="closePopup" class="btn-cancel btn-long mr-4" />
        <UButton :label="okBtn" @click.prevent="$emit('success')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { UButton } from 'unit-uikit'

@Options({
  name: 'DefaultPopup',
  components: {
    UButton
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    cancelBtn: {
      type: String,
      default: 'Отмена'
    },
    okBtn: {
      type: String,
      default: 'Ок'
    }
  },
  data() {
    return {}
  },
  methods: {
    closePopup() {
      this.$emit('close')
    }
  }
})
export default class DefaultPopup extends Vue {}
</script>

<style lang="scss" scoped>
.popup-container {
  padding: 25px;
  text-align: left;
  width: 544px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(7, 8, 54, 0.14);
  border-radius: 12px;
}

.popup-title {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}
</style>
