<template>
  <h2 class="text-2xl font-bold mb-5">Верификация</h2>

  <div class="flex mb-5">
    <div class="relative flex">
      <UInput
        style="max-width: 278px"
        class="flex-grow mr-4"
        label="Верификация"
        :value="getVerificationStatus"
        v-model="getVerificationStatus"
        :disabled="true"
      />
      <USwitch
        class="!absolute right-3 !bg-[#526581]/0 border-0"
        labelON=""
        labelOFF=""
        v-model="isVerified"
        @click="readonly ? () => {} : verifyCompany(!isVerified)"
        :is-disabled="readonly"
      />
    </div>
    <label
      v-if="!readonly"
      for="checkListInput"
      class="flex items-center justify-center flex-grow basis-1/5 border border-black border-solid rounded bg-white hover:bg-gray-100 hover:cursor-pointer max-w-[270px]"
    >
      <img src="/icons/download-photo.svg" class="mr-5 flex-shrink-0" />
      <h3 class="text-xs xl:text-base overflow-hidden">Прикрепить чек лист</h3>
    </label>
    <input
      v-if="!readonly"
      type="file"
      class="hidden"
      name="checkListInput"
      id="checkListInput"
      accept=".pdf"
      @change="uploadCheckList($event)"
    />
  </div>
  <div class="flex mb-5" v-if="checkList">
    <h3 style="max-width: 278px" class="flex-grow mr-4">Последний загруженный чек лист:</h3>
    <a class="flex" :href="checkList.file" target="_blank">{{ checkList.name }}</a>
  </div>

  <h2 class="text-2xl font-bold mb-5">Основная информация</h2>

  <div class="flex mb-5" v-if="type === EEntityServerValues.ooo">
    <UInput
      class="!max-w-none flex-grow basis-1/4 mr-4"
      label="Полное название без аббревиатур"
      :value="full_company_name"
      v-model="full_company_name"
      @keypress.enter="updateCompanyInfo('full_company_name')"
      @blurAction="updateCompanyInfo('full_company_name')"
      :readonly="readonly"
    >
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ full_company_nameError }}</p>
      </template>
    </UInput>
    <div class="!max-w-none flex-grow basis-1/4"></div>
  </div>

  <div class="flex mb-5">
    <UInput
      class="!max-w-none flex-grow"
      label="Юридический адрес"
      :value="registered_address"
      v-model="registered_address"
      @keypress.enter="updateCompanyInfo('registered_address')"
      @blurAction="updateCompanyInfo('registered_address')"
      :readonly="readonly"
    >
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ registered_addressError }}</p>
      </template>
    </UInput>
  </div>

  <div class="flex mb-5">
    <UInput
      class="!max-w-none flex-grow"
      label="Почтовый адрес"
      :value="actual_address"
      v-model="actual_address"
      @keypress.enter="updateCompanyInfo('actual_address')"
      @blurAction="updateCompanyInfo('actual_address')"
      :readonly="readonly"
    >
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ actual_addressError }}</p>
      </template>
    </UInput>
  </div>

  <div class="flex mb-5">
    <UInput
      class="!max-w-none flex-grow basis-1/5 mr-4"
      label="ОКВЭД"
      :value="okved"
      v-model="okved"
      mask="okvedMask"
      @keypress.enter="updateCompanyInfo('okved')"
      @blurAction="updateCompanyInfo('okved')"
      :readonly="readonly"
    >
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ okvedError }}</p>
      </template>
    </UInput>
    <UInput
      class="!max-w-none flex-grow basis-1/5 mr-4"
      label="ОГРН"
      :value="ogrn"
      v-model="ogrn"
      @keypress.enter="updateCompanyInfo('ogrn')"
      @blurAction="updateCompanyInfo('ogrn')"
      :readonly="readonly"
    >
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ ogrnError }}</p>
      </template>
    </UInput>
    <UInput
      class="!max-w-none flex-grow basis-1/5 mr-4"
      label="ИНН"
      :value="inn"
      v-model="inn"
      @keypress.enter="updateCompanyInfo('inn')"
      @blurAction="updateCompanyInfo('inn')"
      :readonly="readonly"
    >
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ innError }}</p>
      </template>
    </UInput>

    <UInput
      class="!max-w-none flex-grow basis-1/5"
      label="КПП"
      :value="kpp"
      v-model="kpp"
      mask="kpp"
      @keypress.enter="updateCompanyInfo('kpp')"
      @blurAction="updateCompanyInfo('kpp')"
      v-if="type === EEntityServerValues.ooo"
      :readonly="readonly"
    >
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ kppError }}</p>
      </template>
    </UInput>
    <div v-else class="!max-w-none flex-grow basis-1/5"></div>
  </div>

  <div class="flex mb-5">
    <UInput
      class="!max-w-none flex-grow basis-1/5 mr-4"
      label="Наименование"
      :value="name"
      v-model="name"
      @keypress.enter="updateCompanyInfo('name')"
      @blurAction="updateCompanyInfo('name')"
      :readonly="readonly"
    >
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ nameError }}</p>
      </template>
    </UInput>
    <UInput
      class="!max-w-none flex-grow basis-1/5 mr-4"
      label="Мобильный телефон"
      mask="phone"
      :value="phone"
      v-model="phone"
      @keypress.enter="updateCompanyInfo('phone')"
      @blurAction="updateCompanyInfo('phone')"
      :readonly="readonly"
    >
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ phoneError }}</p>
      </template>
    </UInput>
    <UInput
      class="!max-w-none flex-grow basis-1/5 mr-4"
      label="Email"
      :value="email"
      v-model="email"
      @keypress.enter="updateCompanyInfo('email')"
      @blurAction="updateCompanyInfo('email')"
      :readonly="readonly"
    >
      <template #icon>
        <p class="absolute top-full text-error text-xs">{{ emailError }}</p>
      </template>
    </UInput>
    <UISelect
      id="position"
      class="!max-w-none flex-grow basis-1/5"
      label="Подписант от лица компании"
      unselectedLabel="Выберите подписанта"
      v-model="signer"
      :value="signer || ''"
      @change="updateCompanyInfo('signer')"
      :options="postionOptions"
      :disabled="readonly"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { UInput, UISelect, USwitch } from 'unit-uikit'

import { EEntityServerValues } from '@/types/api-values'
import { jsonToFormData } from '@/utils/jsonToForm'

function normalizeRepresentativesRecords(item: any) {
  return {
    id: item.id,
    name: `${item?.last_name || ''} ${item?.first_name || ''} ${item?.middle_name || ''} ${item.position === 'director' ? '(гендир)' : ''}`
  }
}

@Options({
  name: 'CompanyMainInfo',
  components: {
    UInput,
    USwitch,
    UISelect
  },
  data() {
    const info = this.info as any
    return {
      checkList: {},
      EEntityServerValues,
      type: info?.type,
      name: info?.name,
      nameError: '',
      ogrn: info?.ogrn,
      ogrnError: '',
      inn: info?.inn,
      innError: '',
      signer: info?.position,
      signerError: '',
      email: info?.email,
      emailError: '',
      phone: info?.phone,
      phoneError: '',
      okved: info?.okved,
      okvedError: '',
      kpp: info?.kpp,
      kppError: '',
      actual_address: info?.actual_address,
      actual_addressError: '',
      registered_address: info?.registered_address,
      registered_addressError: '',
      full_company_name: info?.full_company_name,
      full_company_nameError: '',
      isVerified: !!info?.verified,
      postionOptions: []
    }
  },
  props: {
    info: { type: Object },
    readonly: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    uploadCheckList(event: any) {
      const file = event?.target?.files[0]
      const companyId = this.$route?.params?.id

      const data = {
        company: companyId,
        name: file.name,
        file
      }

      const bodyFormData = jsonToFormData(data)

      return this.$store
        .dispatch('companies/uploadEntityCheckList', bodyFormData)
        .then((res: any) => {
          this.checkList = res
        })
        .catch((err: any) => {
          console.log(err)
        })
    },
    updateCompanyInfo(field: string) {
      if (this.readonly) {
        return
      }

      this[field + 'Error'] = ''
      const id = this.$route?.params?.id
      const data = {
        [field]: this[field]
      }
      if (field === 'phone') {
        data.phone = data.phone.replace(/-/g, '')
      }
      if (field === 'signer') {
        return this.$store
          .dispatch('companies/updateEntitySigner', { id: id, current_signer: this.signer })
          .then(() => {
            this[field + 'Error'] = ''
          })
          .catch((err: any) => {
            console.log(err)
            const data = err.response.data
            const errText = data[field]
            this[field + 'Error'] = errText && errText.join(', ')
          })
      }

      return this.$store
        .dispatch('companies/updateEntityRecord', { id: id, data: data })
        .then(() => {
          this[field + 'Error'] = ''
        })
        .catch((err: any) => {
          console.log(err)
          const data = err.response.data
          const errText = data[field]
          this[field + 'Error'] = errText && errText.join(', ')
        })
    },
    verifyCompany(verified: boolean) {
      this.isVerified = verified

      const id = this.$route?.params?.id

      return this.$store.dispatch('companies/updateEntityRecord', { id: id, data: { verified: verified } }).catch((e: any) => {
        console.error(e)
      })
    }
  },
  computed: {
    getVerificationStatus() {
      return this.isVerified ? 'Верифицирован' : 'Не пройдена'
    }
  },
  created() {
    const id = this.$route.params.id
    if (id) {
      this.$store
        .dispatch('companies/getCheckList', `?company=${id}`)
        .then((res: any) => {
          this.checkList = res[0] || null
        })
        .catch((e: any) => {
          console.error(e)
        })

      this.$store
        .dispatch('companies/getCompanyProcurationRepresentatives', id)
        .then((res: any) => {
          if (res && res.length) {
            this.postionOptions = res.map(normalizeRepresentativesRecords)
          }
        })
        .catch((e: any) => {
          console.error(e)
        })
    }
  }
})
export default class CompanyMainInfo extends Vue {}
</script>
