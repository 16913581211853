<template>
  <span class="tooltip-icon" :data-tooltip="tooltip">i</span>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    tooltip: String
  }
})
export default class Tooltip extends Vue {}
</script>

<style lang="scss" scoped>
  .tooltip-icon {
    display: inline-block;
    width: 13px;
    height: 13px;
    line-height: 11px;
    border-radius: 50%;
    border: 1px solid #C6C6C6;
    color: #C6C6C6;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: 5px;
    right: 5px;

    &:hover {
      color: #000;
      border-color: #000;
      cursor: pointer;
    }

    &:after {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      content: attr(data-tooltip);
      padding: 2px 4px;
      bottom: 100%;
      right: 0;

      white-space: normal;
      color: black;
      min-width: 160px;
      z-index: 2;
      border-radius: 2px;
      transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
      border: 1px solid #000000;
      transform: translate(10px, -5px);

      font-style: normal;
      font-weight: normal;
      font-size: 8px;
      line-height: 8px;
    }
    &:hover {
      &:after {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    }
  }
</style>
