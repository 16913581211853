<template>
  <Loader title="Данные загружаются, пожалуйста подождите" v-if="loading" />
  <div v-if="$route.name === 'staff.verified'" class="hidden close-edit-menu" @click="closeEditMenu"></div>
  <div class="flex mb-5">
    <UInput searchInput placeholder="Поиск" :value="searchString" v-model="searchString" />
  </div>

  <UTable v-if="!loading" colspan="5" :callback="loadWorkers">
    <template #thead>
      <tr>
        <th class="w-[360px]">Сотрудник</th>
        <th class="w-[360px]">Компания</th>
        <th class="w-[260px]">Должность</th>
        <th>Дата регистрации</th>
        <th v-if="$route.name === 'staff.verified'" class="fixed-cell"></th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="coworker in staff" :key="coworker.id">
        <td>
          <div class="flex items-center h-full">
            <router-link :to="`/staff/${coworker.id}`" class="font-bold hover:underline">{{
              getFullName(coworker.company_staff)
            }}</router-link>
          </div>
        </td>
        <td>
          {{ coworker.company_name }}
        </td>
        <td>
          {{ coworker.position }}
        </td>
        <td>
          {{ formatDate(coworker.created) || '' }}
        </td>
        <td v-if="$route.name === 'staff.verified'">
          <button @click="openEditMenu(coworker.id)" type="button" class="edit-btn relative">
            <img class="" src="/icons/extra.svg" alt="Редактировать" />
            <div v-if="coworker.showEditMenu" class="edit-menu">
              <UButton @click="coworkerRemoveVerification(coworker)" label="Убрать верификацию" size="sm" class="px-2 w-auto" />
            </div>
          </button>
        </td>
      </tr>
    </template>
  </UTable>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { UButton, UInput, UTable } from 'unit-uikit'
import { formatDate } from '@/utils/helpers'
import Loader from '@/components/Loader/Loader.vue'

import debounce from '@/utils/debounce'

@Options({
  name: 'StaffList',
  data() {
    return {
      filterString: '',
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      loading: true,
      staff: [],
      searchString: ''
    }
  },
  components: {
    Loader,
    UButton,
    UInput,
    UTable
  },
  created() {
    this.loadContent(false)
  },
  methods: {
    formatDate,
    loadWorkersWithParams() {
      this.scrollData.page = 0
      this.scrollData.count = 1
      this.staff = []
      this.loadWorkers()
    },
    getFullName(coworker: any) {
      return `${coworker.last_name} ${coworker.first_name} ${coworker.middle_name}`
    },
    async loadWorkers() {
      if (this.staff.length < this.scrollData.count) {
        this.scrollData.page++

        let queryString = `?page=${this.scrollData.page}&count=${this.scrollData.size}&user_verified=${
          this.$route.name === 'staff.verified' ? 'true' : 'false'
        }`

        if (this.searchString) {
          queryString += `&last_name=${this.searchString}`
        }

        const response = await this.$store.dispatch('companies/getSupportCoworker', queryString)

        const data = response
        if (data) {
          this.scrollData.count = data?.count
          const coworkers = data
          this.staff.push(...coworkers)
        }
      }
    },
    loadContent(isTabChange: boolean) {
      this.loading = true

      if (isTabChange) {
        this.scrollData.page = 1
      }

      this.$store
        .dispatch(
          'companies/getSupportCoworker',
          `?page=${this.scrollData.page}&count=${this.scrollData.size}&user_verified=${
            this.$route.name === 'staff.verified' ? 'true' : 'false'
          }`
        )
        .then((res: any) => {
          this.scrollData.count = res.count
          this.staff = res
          this.loading = false
        })
        .catch((e: any) => {
          this.loading = false
          console.error(e)
        })
    },
    closeEditMenu() {
      this.staff = this.staff.map((coworker: any) => {
        coworker.showEditMenu = false
        return coworker
      })
    },
    openEditMenu(id: number | string) {
      this.staff = this.staff.map((coworker: any) => {
        if (id + '' === coworker.id + '') {
          coworker.showEditMenu = !coworker.showEditMenu
        } else {
          coworker.showEditMenu = false
        }
        return coworker
      })
    },
    coworkerRemoveVerification(coworker: any) {
      this.$store
        .dispatch('companies/updateCoworker', {
          user: coworker.company_staff.user_id,
          formData: {
            verified: false,
            personal_data: {},
            username: coworker.company_staff.username
          }
        })
        .then((res: any) => {
          this.scrollData.count = res.count
          this.staff = res
          this.loadContent()
          this.loading = false
        })
        .catch((e: any) => {
          this.loading = false
          console.error(e)
        })
    }
  },
  watch: {
    $route(to, from) {
      this.loadContent(true)
    },
    searchString() {
      if (this.debounceLoadWorkers) {
        this.debounceLoadWorkers()
      } else {
        this.debounceLoadWorkers = debounce(() => {
          this.loadWorkersWithParams()
        })
        this.debounceLoadWorkers()
      }
    }
  }
})
export default class StaffList extends Vue {}
</script>
<style lang="postcss" scoped>
:deep(table) {
  @apply table-fixed;
}

td {
  word-break: break-word;
  @apply text-ellipsis text-black max-w-[404px];
}
tr:hover td {
  @apply bg-gray-100 cursor-pointer;
}
.edit-menu {
  @apply top-full right-2/4 translate-x-0 translate-y-0;
}
</style>
