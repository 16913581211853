<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import moment from 'moment'

import { useStore } from 'vuex'
import { formatToServerDate } from '@/utils/helpers'

import Loader from '@/components/Loader/Loader.vue'

import { UButton, UInput, UMultiselect } from 'unit-uikit'
import router from '@/router'

const store = useStore()
const loading = ref(false)

let companiesListOptions: any = ref([])
let selectedCmpIDs: any = ref([])
let initialSelectedCmpIDs: any = ref([])

const selectedCompany = ref([])
const dateStart = ref('')
const dateEnd = ref('')

onMounted(() => {
  store
    .dispatch('companies/getCompaniesList', '')
    .then((res: any) => {
      const companies = res
      const filteredCompanies = companies.filter((company: any) => company.name)

      selectedCompany.value = filteredCompanies

      let allCompaniesWithLabelSelectAll = [
        {
          all: 'Выбрать все',
          options: [...filteredCompanies]
        }
      ]

      companiesListOptions.value = allCompaniesWithLabelSelectAll
      getSelectedCompaniesIDs(true)
    })
    .catch((e) => {
      console.error(e)
    })
})

const isDisabled = computed(() => {
  if (!dateStart.value || !dateEnd.value) {
    return true
  }
})

const clearFilters = () => {
  selectedCompany.value = []
  dateStart.value = ''
  dateEnd.value = ''
}

const getSelectedCompaniesIDs = (allCompanies: boolean) => {
  const selectedCompanies = []
  const selectedIDs: any = []

  selectedCompanies.push(selectedCompany.value)
  selectedCompanies.forEach((company: any) => {
    company.forEach((item: any) => {
      selectedIDs.push(item.id)
    })
  })

  if (allCompanies) {
    initialSelectedCmpIDs.value = selectedIDs
  } else {
    selectedCmpIDs.value = selectedCmpIDs.value.length === companiesListOptions.value[0]?.options.length ? [] : selectedIDs
  }
}

const generateDocs = (financialReport: boolean) => {
  loading.value = true

  const payload = {
    type: financialReport ? 'financial' : 'summary_table',
    name: `${financialReport ? 'Фин. отчет' : 'Выплаты_по_компаниям'} ${moment(new Date()).format('DD.MM.YY_HH:mm:ss')}`,
    date_start: `${formatToServerDate(dateStart.value)}T00:00:00`,
    date_end: `${formatToServerDate(dateEnd.value)}T23:59:59.99`,
    companies: initialSelectedCmpIDs.value && !selectedCmpIDs.value.length ? [] : selectedCmpIDs.value
  }

  store
    .dispatch('main/generateDdsUnload', payload)
    .then(() => {
      router.push('/reportings/history')
      loading.value = false
    })
    .catch((e: any) => {
      console.error(e)
      loading.value = false
    })
}
</script>
<template>
  <Loader v-if="loading" />
  <div class="flex flex-col overflow-hidden overflow-y-auto flex-grow">
    <div class="flex flex-col gap-8">
      <div class="flex gap-3 flex-wrap">
        <div class="relative">
          <span class="absolute text-[0.75rem] text-[#C9CDDD] leading-5 top-[3px] left-2 whitespace-nowrap z-[99]"
            >Наименование компании</span
          >
          <UMultiselect
            @close="getSelectedCompaniesIDs(false)"
            v-model="selectedCompany"
            :options="companiesListOptions"
            :close-on-select="false"
            :multiple="true"
            :searchable="true"
            class="!w-[262px]"
            placeholder="Наименование"
            label="name"
            track-by="id"
            group-values="options"
            group-label="all"
            :group-select="true"
            :showNoOptions="true"
          >
            <template #noOptions>Список компаний пуст</template>
            <template #noResult>Ничего не найдено</template>
            <template #option="option">
              <span :title="option.option?.name || ''">{{ option.option?.name || 'Выбрать все' }}</span>
            </template>
          </UMultiselect>
        </div>
        <UInput v-model="dateStart" :value="dateStart" large class="!w-[160px]" label="Дата начала" placeholder="дд.мм.гггг" mask="date" />
        <UInput v-model="dateEnd" :value="dateEnd" large class="!w-[160px]" label="Дата окончания" placeholder="дд.мм.гггг" mask="date" />
      </div>
    </div>
  </div>
  <div class="flex flex-col">
    <div class="flex gap-6 mb-3 self-end">
      <UButton @click="clearFilters()" label="Отмена" color="secondary" />
      <UButton @click="generateDocs(false)" label="Сводная таблица" :disabled="isDisabled" />
      <UButton @click="generateDocs(true)" label="Выгрузить фин. отчет" :disabled="isDisabled" />
    </div>
  </div>
</template>
<style lang="postcss" scoped>
:deep(.multiselect__tags) {
  @apply h-14;
  padding: 10px 40px 0 8px;
}
:deep(.multiselect__select:before) {
  @apply top-4;
}
:deep(.multiselect__option--group.multiselect__option--highlight) {
  @apply bg-[#ededed]/60;
}
:deep(.multiselect__content .multiselect__element:first-child .multiselect__option:before) {
  @apply hidden;
}
:deep(.multiselect__content .multiselect__element:first-child .multiselect__option span) {
  @apply ml-0;
}
:deep(.multiselect__content-wrapper) {
  @apply w-fit min-w-full;
}
</style>
