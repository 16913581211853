<template>
  <h2 class="text-2xl font-bold mb-5">Список чек листов</h2>
  <table>
    <thead class="h-[40px]">
      <tr class="mt-0">
        <th class="py-0">Файл</th>
        <th class="py-0">Дата загрузки</th>
        <th class="py-0">Кто загрузил</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr v-for="item in checkLists" :key="item.id">
        <td>
          <a :href="item.file" target="_blank">{{ item.name }}</a>
        </td>
        <td>{{ formatDate(item.created) }}</td>
        <td>{{ getFullName(item.initiator) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

import moment from 'moment'

function formatDate(date: any) {
  return moment(new Date(date)).format('DD.MM.YYYY HH:mm')
}

@Options({
  name: 'CompanyCheckList',
  components: {},
  data() {
    return {
      checkLists: []
    }
  },
  porps: {
    readonly: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    formatDate,
    getFullName(worker: any) {
      return `${worker?.username || ''} ${worker?.last_name || ''} ${worker?.first_name || ''} ${worker?.middle_name || ''}`
    }
  },
  created() {
    const id = this.$route.params.id
    if (id) {
      this.$store
        .dispatch('companies/getCheckList', `?company=${id}`)
        .then((res: any) => {
          this.checkLists = res
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }
})
export default class CompanyCheckList extends Vue {}
</script>
