<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import moment from 'moment'

import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { UTabs, UButton, UModal, UInput, UTextarea, UMultiselect, UISelect } from 'unit-uikit'
import ErrorModal from '@/components/Modals/ErrorModal.vue'
import Loader from '@/components/Loader/Loader.vue'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { EUserRoles } from '@/types'

const store = useStore()
const authStore = useAuthStore()
const { userRole } = storeToRefs(authStore)

const companies = ref([])

const company = ref(null)

store
  .dispatch('companies/getCompaniesList', '')
  .then((res: any) => {
    companies.value = res.filter((company: any) => company.name)
  })
  .catch((e: any) => {
    console.error(e)
  })

const route = useRoute()
const showModal = ref(false)

const tabs = ref([]) as any

if (userRole.value !== EUserRoles.support) {
  tabs.value.push(
    {
      routeName: 'deposit.list',
      label: 'Депозиты'
    },
    {
      routeName: 'deposit.billspayments',
      label: 'Счета и платежи'
    }
  )
} else {
  tabs.value.push({
    routeName: 'deposit.billspayments',
    label: 'Счета и платежи'
  })
}

const isAccountsPage = computed(() => {
  const name = route?.name
  return name === 'deposit.billspayments'
})

const toggleModal = () => {
  showModal.value = !showModal.value
}

const _date = moment(new Date()).format('DD.MM.YYYY')

const price = ref('1')
const accountObject = ref('')
const basisPayment = ref('Оплата верификационного платежа Площадки «UNIT» https://lk.theunit.ru')
const accountDate = ref(_date)
const accountPurpose = ref('Оплата верификационного платежа Площадки «UNIT» https://lk.theunit.ru')
const accountDetails = ref(null)
const objectOptions = ref([]) as any

function formatDateForQueryString(date: any) {
  if (date) {
    const dateArr = date.split('.')
    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
  }
  return ''
}

const routerKey = ref(Date.now())
const accountCreating = ref(false)
const failedcreate = ref(false)

const createBillingAccount = () => {
  if (accountCreating.value) {
    return
  }

  accountCreating.value = true

  const amount = price.value?.trim().replaceAll(' ', '').replaceAll(',', '.') || ''
  if (amount && accountPurpose.value) {
    const payload = {
      amount: amount,
      status: 'not_paid',
      objects_list: accountObject.value ? [accountObject.value] : [],
      date: formatDateForQueryString(accountDate.value),
      purpose_of_payment: accountPurpose.value,
      basis_of_payment: basisPayment.value,
      company: company.value?.id,
      account_details: accountDetails.value,
      type: 'invoice'
    }

    store
      .dispatch('bills/createBillingAccount', payload)
      .then(() => {
        accountCreating.value = false
        routerKey.value = Date.now()
        cancelModal()
      })
      .catch((e: any) => {
        console.error(e)
        routerKey.value = Date.now()
        failedcreate.value = true
        accountCreating.value = false
      })
    showModal.value = false
  } else {
    accountCreating.value = false
  }
}

const isNotFullAccountData = computed(() => {
  const _price = parseFloat(price.value?.trim().replaceAll(' ', '').replaceAll(',', '.') || '')
  return (
    !_price || !accountPurpose.value || !company.value || !basisPayment.value || accountCreating.value || accountDate.value?.length !== 10
  )
})

const cancelModal = () => {
  showModal.value = false
  price.value = '1'
  company.value = null
  basisPayment.value = 'Оплата верификационного платежа Площадки «UNIT» https://lk.theunit.ru'
  accountPurpose.value = 'Оплата верификационного платежа Площадки «UNIT» https://lk.theunit.ru'
  accountObject.value = ''
}

store
  .dispatch('bills/getBillingAccountDetails')
  .then((res: any) => {
    if (res && res[0] && res[0].id) {
      accountDetails.value = res[0].id
    }
  })
  .catch((e: any) => {
    console.error(e)
  })

store
  .dispatch('main/getObject')
  .then((res: any) => {
    const data = res
    objectOptions.value = [
      {
        name: 'Без выбора объекта',
        id: ''
      },
      ...data
    ]
  })
  .catch((e: any) => {
    console.error(e)
  })

watch(company, () => {
  const id = company.value?.id
  accountObject.value = ''
  if (id) {
    store
      .dispatch('main/getObject', `?company=${id}`)
      .then((res: any) => {
        const data = res
        objectOptions.value = [
          {
            name: 'Без выбора объекта',
            id: ''
          },
          ...data
        ]
      })
      .catch((e: any) => {
        console.error(e)
      })
  }
})
</script>
<template>
  <Loader v-if="accountCreating" />
  <main-page-header v-if="route.name !== 'deposit.id'" title="Депозиты" class="page__title">
    <u-button v-if="isAccountsPage && userRole !== EUserRoles.support" label="Создать счет" size="sm" @click="toggleModal" />
  </main-page-header>
  <u-tabs v-if="route.name !== 'deposit.id'" :tabs="tabs" />
  <router-view :key="routerKey"></router-view>

  <u-modal title="Пополнить баланс" :show="showModal" @cancel="toggleModal" @success="createBillingAccount">
    <template #content>
      <div class="relative">
        <UMultiselect
          v-model="company"
          :options="companies"
          :multiple="false"
          :searchable="true"
          placeholder="Выберите компанию"
          label="name"
          track-by="id"
          :showLabels="false"
          :showNoOptions="true"
        >
          <template #noOptions>Список объектов пуст</template>
          <template #noResult>Ничего не найдено</template>
        </UMultiselect>
      </div>
      <u-input
        label="Введите сумму пополнения"
        placeholder="000 000,00 ₽"
        mask="valueCurrency"
        :value="price"
        v-model="price"
        :showCurrency="true"
        :disabled="!company"
      />
      <UISelect
        id="objectSelector"
        label="Выберите объект(ы)"
        unselectedLabel="Без выбора объекта"
        v-model="accountObject"
        :value="accountObject || ''"
        :options="objectOptions"
      />
      <u-input
        label="Введите основание платежа"
        placeholder="Основание платежа"
        :value="basisPayment"
        v-model="basisPayment"
        :disabled="!company"
      />
      <u-textarea
        class="max-h-[130px]"
        label="Введите назначение платежа"
        placeholder="Назначение платежа"
        :value="accountPurpose"
        v-model="accountPurpose"
        :disabled="!company"
      />
      <UInput placeholder="Впишите дату" label="Дата счета" mask="date" v-model="accountDate" :value="accountDate" />
    </template>
    <template #buttons>
      <div class="flex gap-3 justify-end">
        <UButton label="Отмена" color="secondary" @click="cancelModal" />
        <UButton label="Создать счет" :disabled="isNotFullAccountData" @click="createBillingAccount" />
      </div>
    </template>
  </u-modal>

  <ErrorModal
    :show="failedcreate"
    :errorMessage="'Не удалось создать счет'"
    :errorAction="
      () => {
        failedcreate = false
      }
    "
  />
</template>

<style lang="postcss" scoped>
:deep(.modal) {
  min-width: 520px;
}
:deep(.multiselect__tags) {
  @apply h-14;
  padding: 10px 40px 0 8px;
}
:deep(.multiselect__select:before) {
  @apply top-4;
}
:deep(.multiselect__option--group.multiselect__option--highlight) {
  @apply bg-[#ededed]/60;
}
:deep(.multiselect__content .multiselect__element .multiselect__option:before) {
  @apply hidden;
}
:deep(.multiselect__content .multiselect__element .multiselect__option:after) {
  @apply hidden;
}
:deep(.multiselect__content .multiselect__element .multiselect__option span) {
  @apply ml-0;
}
:deep(.multiselect__content-wrapper) {
  @apply min-w-full;
}
</style>
