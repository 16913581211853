<template>
  <div>
    <main-page-header :title="`Заказ №${id || ''} ${order.name || ''}`" class="page__title">
      <div id="order-app-header" class="ml-auto"></div>
    </main-page-header>
  </div>
  <ul class="tabs">
    <li class="tabs__item">
      <router-link :to="'/order/' + id">Инфо</router-link>
    </li>
    <li class="tabs__item">
      <router-link :to="'/order/' + id + '/docs'">Документы</router-link>
    </li>
    <li class="tabs__item">
      <router-link :to="'/order/' + id + '/tasks'">Акты</router-link>
    </li>
  </ul>
  <router-view></router-view>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'

@Options({
  name: 'order',
  components: {
    MainPageHeader
  },
  data() {
    return {
      id: '',
      order: {}
    }
  },
  created() {
    this.id = this.$route.params.id

    if (this.id) {
      this.$store
        .dispatch('order/getSingleOrder', this.id)
        .then((response: any) => {
          this.order = response
        })
        .catch((e: any) => {
          console.error(e)
        })
    }
  },
  watch: {
    '$route.params.id'() {
      if (this.$route.params.id) {
        this.id = this.$route.params.id

        this.$store
          .dispatch('order/getSingleOrder', this.id)
          .then((response: any) => {
            this.order = response
          })
          .catch((e: any) => {
            console.error(e)
          })
      }
    }
  }
})
export default class Order extends Vue {}
</script>
