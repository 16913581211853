<script setup lang="ts">
import { PropType } from 'vue'
import { TBillAccount } from '@/types'
import { ETransactionsStatus } from '@/types/api-values'
import { TRANSACTION_COLORS, TRANSACTION_STATUS } from '@/utils/consts'
import { getQiwiErrCode } from '@/views/Reportings/Components/errors'
import moment from 'moment'

const getStatusColor = (status: ETransactionsStatus) => {
  return status && TRANSACTION_COLORS[status] ? TRANSACTION_COLORS[status] : '#27BBDC'
}

const formatStatus = (status: ETransactionsStatus) => {
  return status && TRANSACTION_STATUS[status] ? TRANSACTION_STATUS[status] : ''
}

function formatDate(date: any) {
  return moment(new Date(date)).format('DD.MM.YYYY HH:mm')
}

defineProps({
  account: {
    type: Object as PropType<TBillAccount>,
    required: true
  }
})
</script>

<template>
  <tr class="bg-white">
    <td>
      <p>{{ account.id }}</p>
    </td>
    <td>{{ account.company }}</td>
    <td>
      <div class="flex flex-col whitespace-nowrap">
        <span>{{ account.workerFio }}</span>
        <span v-mask="'phone'">{{ account.workerPhone }}</span>
      </div>
    </td>
    <td
      :style="{
        color: getStatusColor(account.status)
      }"
    >
      {{ formatStatus(account.status) }}
      <p class="heading-gray-3">{{ formatDate(account.created) }}</p>
      <p v-if="account.error" :style="{
        color: TRANSACTION_COLORS[ETransactionsStatus.error]
      }">{{ getQiwiErrCode(account.error?.code) || account.error.text}}</p>
    </td>
    <td>{{ account.bill_uuid }}</td>
  </tr>
</template>
