<script setup lang="ts">
import { ref, Ref, onMounted } from 'vue'
import moment from 'moment'

import { UTable } from 'unit-uikit'
import Loader from '@/components/Loader/Loader.vue'

import { useStore } from 'vuex'

import { TUnloadingReportings } from '@/interfaces/main'

const store = useStore()
const loading = ref(false)
const reportingsHistory: any = ref({}) as Ref<TUnloadingReportings>

const formatDate = (date: any) => {
  if (date) {
    return moment(new Date(date)).format('DD.MM.YYYY')
  }
  return ''
}

const getSecureLink = (link: string) => {
  if (link) {
    return link.replace(/^(http:\/\/)/, 'https://')
  }
  return ''
}

onMounted(() => {
  loading.value = true

  store
    .dispatch('main/getReportingsHistory')
    .then((response: any) => {
      reportingsHistory.value = response || []
      loading.value = false
    })
    .catch((e: any) => {
      console.error(e)
      loading.value = false
    })
})
</script>

<template>
  <Loader v-if="loading" />
  <UTable v-else colspan="5">
    <template #thead>
      <tr>
        <th>Дата отчетности</th>
        <th>Наименование компании</th>
        <th>Кол-во документов</th>
        <th>Отчетный период</th>
        <th>Скачать архив</th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="doc in reportingsHistory" :key="doc.id" class="bg-white">
        <td>{{ formatDate(doc.created) }}</td>
        <td>{{ doc.company }}</td>
        <td>{{ doc.status === 'available' ? doc.document_amount : '' }}</td>
        <td>{{ formatDate(doc.period_begin) }} - {{ formatDate(doc.period_end) }}</td>
        <td>
          <a v-if="doc.file" :href="getSecureLink(doc.file)" download target="_blank" class="flex items-center cursor-pointer">
            <span class="text-sm text-accent mr-1 underline hover:no-underline">Скачать</span>
            <img src="/icons/download.svg" alt="download icon" />
          </a>
        </td>
      </tr>
    </template>
  </UTable>
</template>
