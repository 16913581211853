<template>
  <div class="overlay" v-if="show">
    <div class="modal">
      <header>
        <h3 class="modal-title">{{ title }}</h3>
      </header>

      <UButton class="!w-full" :label="textOk" @click="successAction" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { UButton } from 'unit-uikit'

@Options({
  components: {
    UButton
  },
  props: {
    show: Boolean,
    title: String,
    textOk: String,
    successAction: Function
  }
})
export default class SuccessModal extends Vue {}
</script>

<style lang="scss" scoped>
.modal {
  background-color: white;
  padding: 20px 30px;
  box-shadow: 0px 2px 8px rgba(7, 8, 54, 0.14);
  border-radius: 12px;
}

.modal-title {
  @apply mb-3;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}
</style>
