<template>
  <Loader title="Данные загружаются, пожалуйста подождите" v-if="loading" />
  <div class="overflow-auto" v-if="coworker">
    <main-page-header :title="getFullName(coworker)" class="page__title" />
    <div class="flex overflow-y-auto flex-col md:flex-row">
      <div class="md:mr-9 basis-[544px]">
        <h2 class="text-2xl font-bold mb-5">Гражданство и контактные данные</h2>
        <div class="flex flex-wrap mb-8 gap-5">
          <div class="form-input">
            <UISelect
              @change="changeCoworkerData('citizenship', $event.target.value, true)"
              @keyup.enter="changeCoworkerData('citizenship', $event.target.value, true)"
              class="!min-w-full"
              label="Гражданство"
              unselectedLabel="Выберите гражданство"
              :value="coworker.personaldata.citizenship"
              :options="citizenshipData"
            />
          </div>
          <div class="form-input">
            <UISelect
              @change="changeCoworkerData('', $event.target.value, true)"
              @keyup.enter="changeCoworkerData('', $event.target.value, true)"
              class="!min-w-full"
              label="Тип паспорта"
              unselectedLabel="Выберите тип паспорта"
              :value="coworker.passport_information[0].type"
              :options="passportOptions"
            />
          </div>
          <div class="form-input">
            <UInput
              @change="changeCoworkerData('email', coworker.email)"
              @keyup.enter="changeCoworkerData('email', coworker.email)"
              class="!min-w-full"
              :class="{
                'validate-error__border': v$.coworker.email.$invalid
              }"
              label="Email"
              v-model="coworker.email"
              :value="coworker.email"
            />
            <div class="validate-error" v-if="v$.coworker.email.$dirty && v$.coworker.email.required.$invalid">Обязательное поле</div>
            <div class="validate-error" v-else-if="v$.coworker.email.$dirty && v$.coworker.email.$invalid">Введите корректный email</div>
          </div>
          <div class="form-input">
            <UInput
              @change="changeCoworkerData('username', coworker.username)"
              @keyup.enter="changeCoworkerData('username', coworker.username)"
              class="!min-w-full"
              :class="{
                'validate-error__border': v$.coworker.username.$invalid
              }"
              label="Мобильный телефон"
              v-model="coworker.username"
              :value="coworker.username"
              mask="phone"
            />
            <div class="validate-error" v-if="v$.coworker.username.$dirty && v$.coworker.username.required.$invalid">Обязательное поле</div>
            <div class="validate-error" v-else-if="v$.coworker.username.$dirty && v$.coworker.username.$invalid">
              Введите номер телефона
            </div>
          </div>
          <div class="form-input">
            <UInput readonly class="!min-w-full" label="Компания" v-model="companyStaff.company_name" :value="companyStaff.company_name" />
            <div class="validate-error" v-if="v$.companyStaff.company_name.$dirty && v$.companyStaff.company_name.required.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-else-if="v$.companyStaff.company_name.$dirty && v$.companyStaff.company_name.$invalid">
              Введите должность
            </div>
          </div>
          <div class="form-input">
            <UInput
              @change="changeCoworkerData('position', companyStaff.position)"
              @keyup.enter="changeCoworkerData('position', companyStaff.position)"
              class="!min-w-full"
              label="Должность"
              v-model="companyStaff.position"
              :value="companyStaff.position"
            />
            <div class="validate-error" v-if="v$.companyStaff.position.$dirty && v$.companyStaff.position.required.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-else-if="v$.companyStaff.position.$dirty && v$.companyStaff.position.$invalid">
              Введите должность
            </div>
          </div>
          <div class="form-input">
            <UInput
              @change="changeCoworkerData('inn', coworker.personaldata.inn.replaceAll(' ', ''), true)"
              @keyup.enter="changeCoworkerData('inn', coworker.personaldata.inn.replaceAll(' ', ''), true)"
              class="!min-w-full"
              :class="{
                'validate-error__border': v$.coworker.personaldata.inn.$invalid
              }"
              label="ИНН"
              v-model="coworker.personaldata.inn"
              :value="coworker.personaldata.inn"
              mask="inn"
            />
            <div class="validate-error" v-if="v$.coworker.personaldata.inn.$dirty && v$.coworker.personaldata.inn.required.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-else-if="v$.coworker.personaldata.inn.$dirty && v$.coworker.personaldata.inn.$invalid">
              Введите ИНН
            </div>
          </div>
        </div>
        <h2 class="text-2xl font-bold mb-5">Паспортные данные</h2>
        <div class="flex flex-wrap gap-5 pb-6">
          <div class="form-input">
            <UInput
              @change="changeCoworkerData('last_name', coworker.last_name)"
              @keyup.enter="changeCoworkerData('last_name', coworker.last_name)"
              class="!min-w-full"
              :class="{
                'validate-error__border': v$.coworker.last_name.$invalid
              }"
              label="Фамилия"
              v-model="coworker.last_name"
              :value="coworker.last_name"
            />
            <div class="validate-error" v-if="v$.coworker.last_name.$dirty && v$.coworker.last_name.required.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-else-if="v$.coworker.last_name.$dirty && v$.coworker.last_name.$invalid">Введите фамилию</div>
          </div>
          <div class="form-input">
            <UInput
              @change="changeCoworkerData('first_name', coworker.first_name)"
              @keyup.enter="changeCoworkerData('first_name', coworker.first_name)"
              class="!min-w-full"
              :class="{
                'validate-error__border': v$.coworker.first_name.$invalid
              }"
              label="Имя"
              v-model="coworker.first_name"
              :value="coworker.first_name"
            />
            <div class="validate-error" v-if="v$.coworker.first_name.$dirty && v$.coworker.first_name.required.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-else-if="v$.coworker.first_name.$dirty && v$.coworker.first_name.$invalid">Введите имя</div>
          </div>
          <div class="form-input">
            <UInput
              @change="changeCoworkerData('middle_name', coworker.middle_name)"
              @keyup.enter="changeCoworkerData('middle_name', coworker.middle_name)"
              class="!min-w-full"
              :class="{
                'validate-error__border': v$.coworker.middle_name.$invalid
              }"
              label="Отчество"
              v-model="coworker.middle_name"
              :value="coworker.middle_name"
            />
            <div class="validate-error" v-if="v$.coworker.middle_name.$dirty && v$.coworker.middle_name.required.$invalid">
              Обязательное поле
            </div>
            <div class="validate-error" v-else-if="v$.coworker.middle_name.$dirty && v$.coworker.middle_name.$invalid">Введите имя</div>
          </div>
          <div class="form-input">
            <UISelect
              @change="changeCoworkerData('sex', $event.target.value, true)"
              @keyup.enter="changeCoworkerData('sex', $event.target.value, true)"
              class="!min-w-full"
              label="Пол"
              unselectedLabel="Выберите пол"
              :value="coworker.personaldata.sex"
              :options="genderOptions"
            />
          </div>
          <div class="flex justify-between w-full">
            <div class="mr-5">
              <UInput
                @change="changeCoworkerData('passport_series', coworker.passport_information[0].passport_series, true)"
                @keyup.enter="changeCoworkerData('passport_series', coworker.passport_information[0].passport_series, true)"
                class="!min-w-full"
                :class="{
                  'validate-error__border': v$.coworker.passport_information[0].passport_series.$invalid
                }"
                label="Серия"
                v-model="coworker.passport_information[0].passport_series"
                :value="coworker.passport_information[0].passport_series"
              />
              <div
                class="validate-error"
                v-if="
                  v$.coworker.passport_information[0].passport_series.$dirty &&
                  v$.coworker.passport_information[0].passport_series.required.$invalid
                "
              >
                Обязательное поле
              </div>
              <div
                class="validate-error"
                v-else-if="
                  v$.coworker.passport_information[0].passport_series.$dirty && v$.coworker.passport_information[0].passport_series.$invalid
                "
              >
                Введите серию паспорта
              </div>
            </div>
            <div class="mr-5">
              <UInput
                @change="changeCoworkerData('passport_number', coworker.passport_information[0].passport_number, true)"
                @keyup.enter="changeCoworkerData('passport_number', coworker.passport_information[0].passport_number, true)"
                class="!min-w-full"
                :class="{
                  'validate-error__border': v$.coworker.passport_information[0].passport_number.$invalid
                }"
                label="Номер"
                v-model="coworker.passport_information[0].passport_number"
                :value="coworker.passport_information[0].passport_number"
              />
              <div
                class="validate-error"
                v-if="
                  v$.coworker.passport_information[0].passport_number.$dirty &&
                  v$.coworker.passport_information[0].passport_number.required.$invalid
                "
              >
                Обязательное поле
              </div>
              <div
                class="validate-error"
                v-else-if="
                  v$.coworker.passport_information[0].passport_number.$dirty && v$.coworker.passport_information[0].passport_number.$invalid
                "
              >
                Введите номер паспорта
              </div>
            </div>
            <div>
              <UInput
                @change="changeCoworkerData('subdivision_code', coworker.passport_information[0].subdivision_code, true)"
                @keyup.enter="changeCoworkerData('subdivision_code', coworker.passport_information[0].subdivision_code, true)"
                class="!min-w-full"
                :class="{
                  'validate-error__border': v$.coworker.passport_information[0].subdivision_code.$invalid
                }"
                label="Код подразделения"
                v-model="coworker.passport_information[0].subdivision_code"
                :value="coworker.passport_information[0].subdivision_code"
                mask="subdivision"
              />
              <div
                class="validate-error"
                v-if="
                  v$.coworker.passport_information[0].subdivision_code.$dirty &&
                  v$.coworker.passport_information[0].subdivision_code.required.$invalid
                "
              >
                Обязательное поле
              </div>
              <div
                class="validate-error"
                v-else-if="
                  v$.coworker.passport_information[0].subdivision_code.$dirty &&
                  v$.coworker.passport_information[0].subdivision_code.$invalid
                "
              >
                Введите код подразделения
              </div>
            </div>
          </div>
          <div class="form-input__long">
            <UInput
              @change="changeCoworkerData('passport_whom_given', coworker.passport_information[0].passport_whom_given, true)"
              @keyup.enter="changeCoworkerData('passport_whom_given', coworker.passport_information[0].passport_whom_given, true)"
              class="!max-w-full"
              :class="{
                'validate-error__border': v$.coworker.passport_information[0].passport_whom_given.$invalid
              }"
              label="Кем выдан"
              v-model="coworker.passport_information[0].passport_whom_given"
              :value="coworker.passport_information[0].passport_whom_given"
            />
            <div
              class="validate-error"
              v-if="
                v$.coworker.passport_information[0].passport_whom_given.$dirty &&
                v$.coworker.passport_information[0].passport_whom_given.required.$invalid
              "
            >
              Обязательное поле
            </div>
            <div
              class="validate-error"
              v-else-if="
                v$.coworker.passport_information[0].passport_whom_given.$dirty &&
                v$.coworker.passport_information[0].passport_whom_given.$invalid
              "
            >
              Введите значение
            </div>
          </div>
          <div class="form-input">
            <UInput
              @change="changeCoworkerData('date_of_issue', coworker.passport_information[0].date_of_issue, true)"
              @keyup.enter="changeCoworkerData('date_of_issue', coworker.passport_information[0].date_of_issue, true)"
              class="!min-w-full"
              :class="{
                'validate-error__border': v$.coworker.passport_information[0].date_of_issue.$invalid
              }"
              label="Дата выдачи паспорта"
              v-model="coworker.passport_information[0].date_of_issue"
              :value="getFormatedDate(coworker.passport_information[0].date_of_issue)"
              mask="dateMaskCoworker"
            />
            <div
              class="validate-error"
              v-if="
                v$.coworker.passport_information[0].date_of_issue.$dirty &&
                v$.coworker.passport_information[0].date_of_issue.required.$invalid
              "
            >
              Обязательное поле
            </div>
            <div
              class="validate-error"
              v-else-if="
                v$.coworker.passport_information[0].date_of_issue.$dirty && v$.coworker.passport_information[0].date_of_issue.$invalid
              "
            >
              Введите дату
            </div>
          </div>
          <div class="form-input">
            <UInput
              @change="changeCoworkerData('passport_expires', coworker.passport_information[0].passport_expires, true)"
              @keyup.enter="changeCoworkerData('passport_expires', coworker.passport_information[0].passport_expires, true)"
              class="!min-w-full"
              label="Дата окончания срока действия"
              v-model="coworker.passport_information[0].passport_expires"
              :value="getFormatedDate(coworker.passport_information[0].passport_expires)"
              mask="dateMaskCoworker"
            />
            <!-- <div class="validate-error" v-if="v$.coworker.passport_information.passportExp.$dirty && v$.coworker.passport_information.passportExp.required.$invalid">
            Обязательное поле
          </div>
          <div class="validate-error" v-else-if="v$.coworker.passport_information.passportExp.$dirty && v$.coworker.passport_information.passportExp.$invalid">Введите дату</div> -->
          </div>
          <div class="form-input">
            <UInput
              @change="changeCoworkerData('place_of_birth', coworker.passport_information[0].place_of_birth, true)"
              @keyup.enter="changeCoworkerData('place_of_birth', coworker.passport_information[0].place_of_birth, true)"
              class="!min-w-full"
              :class="{
                'validate-error__border': v$.coworker.passport_information[0].place_of_birth.$invalid
              }"
              label="Место рождения"
              v-model="coworker.passport_information[0].place_of_birth"
              :value="coworker.passport_information[0].place_of_birth"
            />
            <div
              class="validate-error"
              v-if="
                v$.coworker.passport_information[0].place_of_birth.$dirty &&
                v$.coworker.passport_information[0].place_of_birth.required.$invalid
              "
            >
              Обязательное поле
            </div>
            <div
              class="validate-error"
              v-else-if="
                v$.coworker.passport_information[0].place_of_birth.$dirty && v$.coworker.passport_information[0].place_of_birth.$invalid
              "
            >
              Введите место рождения
            </div>
          </div>
          <div class="form-input">
            <UInput
              @change="changeCoworkerData('date_of_birth', coworker.personaldata.date_of_birth, true)"
              @keyup.enter="changeCoworkerData('date_of_birth', coworker.personaldata.date_of_birth, true)"
              class="!min-w-full"
              label="Дата рождения"
              :class="{
                'validate-error__border': v$.coworker.personaldata.date_of_birth.$invalid
              }"
              v-model="coworker.personaldata.date_of_birth"
              :value="getFormatedDate(coworker.personaldata.date_of_birth)"
              mask="dateMaskCoworker"
            />
            <div
              class="validate-error"
              v-if="v$.coworker.personaldata.date_of_birth.$dirty && v$.coworker.personaldata.date_of_birth.required.$invalid"
            >
              Обязательное поле
            </div>
            <div
              class="validate-error"
              v-else-if="v$.coworker.personaldata.date_of_birth.$dirty && v$.coworker.personaldata.date_of_birth.$invalid"
            >
              Введите дату рождения
            </div>
          </div>
          <div class="form-input__long">
            <UInput
              @change="changeCoworkerData('registered_address', coworker.passport_information[0].registered_address, true)"
              @keyup.enter="changeCoworkerData('registered_address', coworker.passport_information[0].registered_address, true)"
              class="!max-w-full"
              :class="{
                'validate-error__border': v$.coworker.passport_information[0].registered_address.$invalid
              }"
              label="Адрес прописки"
              v-model="coworker.passport_information[0].registered_address"
              :value="coworker.passport_information[0].registered_address"
            />
            <div
              class="validate-error"
              v-if="
                v$.coworker.passport_information[0].registered_address.$dirty &&
                v$.coworker.passport_information[0].registered_address.required.$invalid
              "
            >
              Обязательное поле
            </div>
            <div
              class="validate-error"
              v-else-if="
                v$.coworker.passport_information[0].registered_address.$dirty &&
                v$.coworker.passport_information[0].registered_address.$invalid
              "
            >
              Введите адрес
            </div>
          </div>
        </div>
      </div>
      <div class="basis-[426px]">
        <h2 class="text-2xl font-bold mb-5 mt-5 md:mt-0">Паспорт</h2>
        <div class="passport-photo">
          <img :src="mainPhoto" alt="Паспорт" title="Паспорт" />
          <div class="passport-photo__reload">
            <div @click="showPassportPhotoModal()" class="passport-photo__reload-glass">
              <img src="/icons/glass.svg" alt="glass-icon" />
            </div>
            <label for="upload">
              <img class="absolute opacity-0" src="/img/icons/uploadbtn.png" alt="Выбрать файл" title="Выбрать файл" />
              <button class="passport-photo__reload-btn">Перезагрузить фото</button>
              <input
                accept="image/*"
                id="upload"
                class="hidden"
                type="file"
                @change="reloadPassportPhoto(coworker.passport_information[0], galleryTab, $event.target.files[0])"
              />
            </label>
          </div>
        </div>
        <div class="flex">
          <div class="img-switcher" :class="{ 'img-switcher--active': galleryTab === 1 }" @click="changeGalleryTab(1)">
            <img
              class="controls-img"
              :src="getPhotoSrc(coworker.passport_information[0].passport_photo_registered_address)"
              alt="passport"
            />
          </div>
          <div class="img-switcher" :class="{ 'img-switcher--active': galleryTab === 2 }" @click="changeGalleryTab(2)">
            <img class="controls-img" :src="getPhotoSrc(coworker.passport_information[0].passport_photo_reversal)" alt="passport" />
          </div>
          <div class="img-switcher" :class="{ 'img-switcher--active': galleryTab === 3 }" @click="changeGalleryTab(3)">
            <img
              class="controls-img"
              :src="getPhotoSrc(coworker.passport_information[0].selfie ? coworker.passport_information[0].selfie : '')"
              alt="passport"
            />
          </div>
          <div class="relative flex">
            <UInput
              style="max-width: 278px"
              class="flex-grow mr-4"
              label="Верифицикация"
              :value="getVerificationStatus"
              v-model="getVerificationStatus"
              :disabled="true"
            >
            </UInput>
            <USwitch
              class="!absolute right-3 !bg-[#526581]/0 border-0"
              labelON=""
              labelOFF=""
              v-model="isVerified"
              @click="isVerified = !isVerified"
              :disabled="isVerified"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isPassportModalShow" class="passport-modal">
      <div class="passport-modal__img">
        <img
          @click="isPassportModalShow = false"
          class="self-end mb-2 cursor-pointer hover:opacity-60"
          src="/icons/red_close.svg"
          alt="Закрыть"
        />
        <img :src="mainPhoto" alt="Паспорт" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Loader from '@/components/Loader/Loader.vue'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { UInput, UISelect, USwitch } from 'unit-uikit'

import useValidate from '@vuelidate/core'
import { required, email, minLength, maxLength } from '@vuelidate/validators'
import { formatDate } from '@/utils/helpers'
import COUNTRIES from '@/utils/Countries'
import { SERVER_COUNTRIES } from '@/utils/consts'
import { jsonToFormData } from '@/utils/jsonToForm'

@Options({
  name: 'StaffInfo',
  components: {
    Loader,
    UInput,
    MainPageHeader,
    UISelect,
    USwitch
  },
  data() {
    return {
      v$: useValidate(),
      mediaURL: process.env.VUE_APP_URL + '/media/',
      isVerified: false,
      verificationStatus: null,
      galleryTab: 1,
      mainPhoto: '/img/icons/passport_empty.png',
      citizenshipData: [
        {
          id: SERVER_COUNTRIES.RUSSIA,
          name: COUNTRIES[SERVER_COUNTRIES.RUSSIA]
        },
        {
          id: SERVER_COUNTRIES.BELARUS,
          name: COUNTRIES[SERVER_COUNTRIES.BELARUS]
        },
        {
          id: SERVER_COUNTRIES.ARMENIA,
          name: COUNTRIES[SERVER_COUNTRIES.ARMENIA]
        },
        {
          id: SERVER_COUNTRIES.KAZAKHSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KAZAKHSTAN]
        },
        {
          id: SERVER_COUNTRIES.KYRGYZSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KYRGYZSTAN]
        },
        {
          id: SERVER_COUNTRIES.UKRAINE,
          name: COUNTRIES[SERVER_COUNTRIES.UKRAINE]
        },
        {
          id: SERVER_COUNTRIES.DNR,
          name: COUNTRIES[SERVER_COUNTRIES.DNR]
        },
        {
          id: SERVER_COUNTRIES.LNR,
          name: COUNTRIES[SERVER_COUNTRIES.LNR]
        }
      ],
      genderOptions: [
        {
          id: 'Муж',
          name: 'Мужской'
        },
        {
          id: 'Жен',
          name: 'Женский'
        }
      ],
      passportOptions: [
        {
          id: 'Внутренний',
          name: 'Внутренний'
        },
        {
          id: 'Заграничный',
          name: 'Заграничный'
        }
      ],
      companyStaff: null,
      coworker: null,
      isPassportModalShow: false,
      loading: false
    }
  },
  validations() {
    return {
      coworker: {
        email: { email, required },
        username: { required },
        last_name: { required, maxLength: maxLength(150) },
        first_name: { required, maxLength: maxLength(150) },
        middle_name: { required, maxLength: maxLength(150) },
        personaldata: {
          inn: { required, minLength: minLength(12), maxLength: maxLength(14) },
          date_of_birth: { required }
        },
        passport_information: [
          {
            passport_series: { required },
            passport_number: { required },
            subdivision_code: { required },
            passport_whom_given: { required },
            date_of_issue: { required },
            place_of_birth: { required },
            registered_address: { required, maxLength: maxLength(250) }
          }
        ]
      },
      companyStaff: {
        company_name: { required },
        position: { required }
      }
    }
  },
  created() {
    this.getSingleCoworkerData()
  },
  methods: {
    formatDate,
    getSingleCoworkerData() {
      this.$store
        .dispatch('companies/getCoworker', this.$route.params.id)
        .then((res: any) => {
          this.coworker = res.worker
          this.companyStaff = res.company_staff
          if (res.worker.passport_information[0].passport_photo_registered_address) {
            this.mainPhoto = this.mediaURL + res.worker.passport_information[0].passport_photo_registered_address
          }
          if (this.isVerified !== res.verified) {
            this.isVerified = res.worker.verified
          }
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    getFullName(coworker: any) {
      return `${coworker.last_name} ${coworker.first_name} ${coworker.middle_name}`
    },
    getFormatedDate(date: string) {
      if (date) {
        let formatedDate: string[] = []
        formatedDate = date.split('-')
        return `${formatedDate[2]}.${formatedDate[1]}.${formatedDate[0]}`
      }
    },
    getPhotoSrc(link: string) {
      if (link.length === 0) return '/img/icons/passport_empty.png'
      return this.mediaURL + link
    },
    changeGalleryTab(tab: number) {
      this.galleryTab = tab
      if (tab === 1) {
        this.mainPhoto = this.mediaURL + this.coworker.passport_information[0].passport_photo_registered_address
      }
      if (tab === 2) {
        this.mainPhoto = this.mediaURL + this.coworker.passport_information[0].passport_photo_reversal
      }
      if (tab === 3) {
        this.mainPhoto = this.mediaURL + this.coworker.passport_information[0].selfie
      }
    },
    showPassportPhotoModal() {
      this.isPassportModalShow = true
    },
    reloadPassportPhoto(passportInfo: any, passportPhotoType: any, uploadedImg: any) {
      let data: any = {}
      data.username = this.coworker.username
      data.personal_data = {}

      if (passportInfo.type === 'Внутренний' && passportPhotoType === 1) {
        data.personal_data.inner_passport = {}
        data.personal_data.inner_passport.passport_photo_registered_address = uploadedImg
        data.personal_data.inner_passport.type = passportInfo.type
      } else if (passportInfo.type === 'Внутренний' && passportPhotoType === 2) {
        data.personal_data.inner_passport = {}
        data.personal_data.inner_passport.passport_photo_reversal = uploadedImg
        data.personal_data.inner_passport.type = passportInfo.type
      } else if (passportInfo.type === 'Внутренний' && passportPhotoType === 3) {
        data.personal_data.inner_passport = {}
        data.personal_data.inner_passport.passport_selfie = uploadedImg
        data.personal_data.inner_passport.type = passportInfo.type
      }
      if (passportInfo.type === 'Заграничный' && passportPhotoType === 2) {
        data.personal_data.foreign_passport = {}
        data.personal_data.foreign_passport.passport_photo_registered_address = uploadedImg
        data.personal_data.foreign_passport.type = passportInfo.type
      } else if (passportInfo.type === 'Заграничный' && passportPhotoType === 2) {
        data.personal_data.foreign_passport = {}
        data.personal_data.foreign_passport.passport_photo_reversal = uploadedImg
        data.personal_data.foreign_passport.type = passportInfo.type
      } else if (passportInfo.type === 'Заграничный' && passportPhotoType === 3) {
        data.personal_data.inner_passport = {}
        data.personal_data.inner_passport.passport_selfie = uploadedImg
        data.personal_data.inner_passport.type = passportInfo.type
      }

      const bodyFormData = jsonToFormData(data)

      this.loading = true

      this.$store
        .dispatch('companies/updateCoworker', { user: this.coworker.personaldata.user, formData: bodyFormData })
        .then(() => {
          this.loading = false
          this.getSingleCoworkerData()
        })
        .catch((e: any) => {
          this.loading = false
          console.error(e)
        })
    },
    changeCoworkerData(key: string, value: string, isPersonalData: boolean) {
      this.v$.$validate()
      // console.log(this.v$.$error)
      // if (!this.v$.$error) {
      let data: any = {}

      if (key === 'username' || key === 'email' || key === 'first_name' || key === 'last_name' || key === 'middle_name') {
        data.username = this.coworker.username
        data.personal_data = {}
        data[key] = value
      }

      if (key === 'position') {
        data.company_id = this.companyStaff.company
        data.username = this.coworker.username
        data.personal_data = {}
        data[key] = value
      }

      if (!isPersonalData) {
        data[key] = value
      } else {
        data.username = this.coworker.username
        data.personal_data = {}
        let formatedData: string[] = []
        if (value === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.type = value
        }
        if (value === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.type = value
        }
        if (key === 'date_of_birth') {
          formatedData = value.split('.')
          data.personal_data.date_of_birth = `${formatedData[2]}-${formatedData[1]}-${formatedData[0]}`
        }
        if (key === 'passport_series' && this.coworker.passport_information[0].type === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.passport_series = value
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_series' && this.coworker.passport_information[0].type === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.passport_series = value
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_number' && this.coworker.passport_information[0].type === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.passport_number = value
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_number' && this.coworker.passport_information[0].type === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.passport_number = value
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_whom_given' && this.coworker.passport_information[0].type === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.passport_whom_given = value
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_whom_given' && this.coworker.passport_information[0].type === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.passport_whom_given = value
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'date_of_issue' && this.coworker.passport_information[0].type === 'Внутренний') {
          formatedData = value.split('.')
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.date_of_issue = `${formatedData[2]}-${formatedData[1]}-${formatedData[0]}`
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'date_of_issue' && this.coworker.passport_information[0].type === 'Заграничный') {
          formatedData = value.split('.')
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.date_of_issue = `${formatedData[2]}-${formatedData[1]}-${formatedData[0]}`
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_expires' && this.coworker.passport_information[0].type === 'Внутренний') {
          formatedData = value.split('.')
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.passport_expires = `${formatedData[2]}-${formatedData[1]}-${formatedData[0]}`
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'passport_expires' && this.coworker.passport_information[0].type === 'Заграничный') {
          formatedData = value.split('.')
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.passport_expires = `${formatedData[2]}-${formatedData[1]}-${formatedData[0]}`
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'place_of_birth' && this.coworker.passport_information[0].type === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.place_of_birth = value
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'place_of_birth' && this.coworker.passport_information[0].type === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.place_of_birth = value
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'registered_address' && this.coworker.passport_information[0].type === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.registered_address = value
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'registered_address' && this.coworker.passport_information[0].type === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.registered_address = value
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'subdivision_code' && this.coworker.passport_information[0].type === 'Внутренний') {
          data.personal_data.inner_passport = {}
          data.personal_data.inner_passport.subdivision_code = value.replace('-', '')
          data.personal_data.inner_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (key === 'subdivision_code' && this.coworker.passport_information[0].type === 'Заграничный') {
          data.personal_data.foreign_passport = {}
          data.personal_data.foreign_passport.subdivision_code = value.replace('-', '')
          data.personal_data.foreign_passport.type = this.coworker.passport_information[0].type
          data.personal_data.citizenship = this.coworker.personaldata.citizenship
        }
        if (
          value !== 'Заграничный' &&
          value !== 'Внутренний' &&
          key !== 'passport_series' &&
          key !== 'passport_number' &&
          key !== 'passport_whom_given' &&
          key !== 'date_of_issue' &&
          key !== 'date_of_birth' &&
          key !== 'passport_expires' &&
          key !== 'place_of_birth' &&
          key !== 'registered_address'
        ) {
          data.username = this.coworker.username
          data.personal_data[key] = value
        }
      }
      this.loading = true

      this.$store
        .dispatch('companies/updateCoworker', {
          user: this.coworker.personaldata.user,
          formData: data
        })
        .then(() => {
          this.loading = false
        })
        .catch((e: any) => {
          console.error(e)
          this.loading = false
        })
    }
  },
  computed: {
    getVerificationStatus() {
      return this.isVerified ? 'Пройдена' : 'Не пройдена'
    }
  },
  watch: {
    isVerified() {
      this.loading = true

      this.$store
        .dispatch('companies/updateCoworker', {
          user: this.coworker.personaldata.user,
          formData: {
            username: this.coworker.username,
            verified: this.isVerified,
            personal_data: {}
          }
        })
        .then(() => {
          this.loading = false
          this.getSingleCoworkerData()
        })
        .catch((e: any) => {
          console.error(e)
          this.loading = false
          console.error(e)
        })
    }
  }
})
export default class StaffInfo extends Vue {}
</script>
<style lang="postcss" scoped>
.form-input {
  @apply relative flex-grow xl:max-w-[262px] xl:min-w-[262px];
  &__long {
    @apply relative flex-grow w-full;
  }
  & > .select {
    @apply w-[156px] xl:w-auto;
  }
}
.passport-photo {
  @apply flex flex-col place-content-center relative mb-6 hover:cursor-pointer;
  &__reload {
    @apply absolute hidden self-center;
  }
  &__reload-glass {
    @apply bg-white w-9 h-9 mb-4 p-[0.2rem] rounded border border-black hover:bg-gray-100;
  }
  &__reload-btn {
    @apply bg-white rounded border border-black py-[14px] px-[10px] h-[52px] hover:bg-gray-100 font-bold;
  }
}
.passport-photo:hover .passport-photo__reload {
  @apply flex flex-col items-center;
}

.passport-modal {
  @apply bg-gray-500/40 flex flex-col justify-center place-items-center fixed inset-0 z-50;
  &__img {
    @apply flex flex-col overflow-y-auto p-4 max-w-[70rem];
  }
}

.img-switcher {
  @apply w-9 h-12 mr-3 border-[3px] border-[#A8A8A9] rounded hover:cursor-pointer;
  &--active {
    @apply border-black;
  }
  &:nth-child(3) {
    @apply mr-8;
  }
}
.controls-img {
  @apply min-w-[30px] object-cover w-full h-full;
}

.validate-error {
  @apply absolute text-xs text-red-600;
  &__border {
    @apply !border-red-600 !border;
  }
}
</style>
