<template>
  <div class="flex">
    <UInput searchInput placeholder="Поиск по Номеру" :value="searcUser" v-model="searcUser" />
  </div>

  <div class="flex flex-col flex-grow space-y-2 md:space-y-6 overflow-hidden">
    <div class="flex flex-row overflow-hidden items-start">
      <div class="flex-grow overflow-auto h-full" ref="orderLIstWrapper">
        <table>
          <thead class="h-[40px]">
            <tr class="mt-0">
              <th colspan="2" class="py-0">Пользователи</th>
            </tr>
          </thead>
          <tbody class="table-body" ref="orderLIstContent">
            <tr
              v-for="user in users"
              :key="user.id"
              :class="{
                'field-selected': false
              }"
            >
              <td class="w-4" v-if="!readonly">
                <button class="agent-select-btn" type="button" @click="addUser(user)">+</button>
              </td>
              <td class="w-4" v-else>
                <button class="agent-select-btn" type="button" disabled="true">+</button>
              </td>
              <td class="pl-0">
                <UsersTableItem :user="user" />
              </td>
            </tr>
            <tr v-if="infiniteLoader">
              <td colspan="2" class="text-center">Загружается</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="flex-grow overflow-auto h-full">
        <table>
          <thead class="h-[40px]">
            <tr class="mt-0">
              <th colspan="2" class="py-0">Выбранные пользователи</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <template v-for="user in selectedUsers" :key="user.id">
              <tr>
                <td class="w-4" v-if="!readonly">
                  <button class="agent-select-btn" type="button" @click="removeUser(user)">-</button>
                </td>
                <td class="w-4" v-else>
                  <button class="agent-select-btn" type="button" disabled="true">-</button>
                </td>
                <td class="pl-0">
                  <div class="worker-description">
                    <UsersTableItem :user="user" />
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="mt-5" v-if="!readonly">
    <UButton id="AddNewUserBtn" label="Добавить нового пользователя" size="xl" class="flex-grow md:flex-grow-0" @click="openNewUserModal" />
  </div>

  <template v-if="showModal">
    <NewUserModal :show="showModal" title="Добавление пользователя" @closeModal="showModal = false" @successModal="openSuccessModal" />
  </template>

  <SuccessModal
    :show="successCreated"
    title="Пользователь успешно добавлен"
    textOk="Ок"
    :successAction="
      () => {
        this.successCreated = false
      }
    "
  />

  <ErrorModal
    :show="showErrorPopup"
    :errorAction="
      () => {
        this.showErrorPopup = false
      }
    "
  />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

import { UButton, UInput } from 'unit-uikit'

import NewUserModal from '@/components/Modals/NewUserModal.vue'
import ErrorModal from '@/components/Modals/ErrorModal.vue'
import SuccessModal from '@/components/Modals/SuccessModal.vue'

import UsersTableItem from './Components/UsersTableItem.vue'

import debounce from '@/utils/debounce'

@Options({
  name: 'CompanyAccesses',
  components: {
    UsersTableItem,
    UInput,
    UButton,
    NewUserModal,
    SuccessModal,
    ErrorModal
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ready: false,
      searcUser: '',
      infiniteLoader: false,
      selectedUsers: [],
      users: [],
      showModal: false,
      successCreated: false,
      showErrorPopup: false
    }
  },
  methods: {
    openSuccessModal(error: any) {
      this.closeNewUserModal()
      this.getUsersList()
      if (error) {
        this.showErrorPopup = true
      } else {
        this.successCreated = true
      }
    },
    openNewUserModal() {
      this.showModal = true
    },
    closeNewUserModal() {
      this.showModal = false
    },
    getUsersList() {
      let queryString = ''
      if (this.searcUser) {
        queryString += `?username=${this.searcUser}`
      }

      const companyId = this.$route.params.id
      const queryCompanyString = queryString ? queryString + `&company_id=${companyId}` : `?company_id=${companyId}`

      this.$store
        .dispatch('companies/getCustomersList', queryString)
        .then((res: any) => {
          const data = res
          this.users = data || []
        })
        .catch((err: any) => {
          console.error(err)
        })

      this.$store
        .dispatch('companies/getCustomersList', queryCompanyString)
        .then((res: any) => {
          const data = res
          this.selectedUsers = data || []
        })
        .catch((err: any) => {
          console.error(err)
        })
    },
    removeUser(user: any) {
      const companyId = this.$route.params.id

      this.$store
        .dispatch('companies/removeUser', {
          company_id: companyId,
          user_id: user.id
        })
        .then(() => {
          this.getUsersList()
        })
        .catch(() => {
          this.showErrorPopup = true
        })
    },
    addUser(user: any) {
      const companyId = this.$route.params.id

      this.$store
        .dispatch('companies/addUser', {
          company_id: companyId,
          user_id: user.id
        })
        .then(() => {
          this.getUsersList()
        })
        .catch(() => {
          this.showErrorPopup = true
        })
    }
  },
  created() {
    this.getUsersList()
  },
  watch: {
    searcUser() {
      if (this.debounceGetUsersList) {
        this.debounceGetUsersList()
      } else {
        this.debounceGetUsersList = debounce(() => {
          this.getUsersList()
        })
        this.debounceGetUsersList()
      }
    }
  }
})
export default class CompanyAccesses extends Vue {}
</script>

<style lang="postcss" scoped>
.agent-select-btn {
  @apply w-5 h-5 leading-4 text-center text-lg text-[#c4c4c4] rounded border-2 border-[#c4c4c4] hover:opacity-60;
}

.table-body tr td {
  @apply bg-white;
}

.field-selected td {
  @apply !bg-[#d7e5d8];
}
</style>
