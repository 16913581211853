<template>
  <ul class="flex flex-col overflow-hidden">
    <li>
      <h2 class="font-bold mb-2">{{ title }}</h2>
    </li>
    <template v-for="item in templates" :key="item.id">
      <li class="flex items-center mb-2">
        <a
          :href="getFileUrl(item.file)"
          target="_blank"
          class="flex-grow overflow-hidden hover:underline mr-2"
          :title="item.name"
          :download="item.name"
          >{{ item.name }}</a
        >

        <UButton v-if="!readonly" title="Редактировать" label="" size="sm" class="!bg-gray-100 border hover:opacity-60 !w-4 mr-3" @click="updateDoc(item)">
          <img class="min-w-fit" src="/icons/pencil.svg" alt="Редактировать" />
        </UButton>

        <UButton v-if="!readonly" title="Удалить" label="" size="sm" class="!bg-gray-100 border hover:opacity-60 !w-4" @click="deleteDoc(item)">
          <img class="min-w-fit" src="/icons/mistake.svg" alt="Редактировать" />
        </UButton>
      </li>
    </template>
    <li class="flex mt-auto mb-4">
      <UButton v-if="!readonly" label="Добавить" class="px-2 !justify-between overflow-hidden !min-w-[200px] w-full !h-[44px]" @click="addNewDoc" />
    </li>
  </ul>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { UButton } from 'unit-uikit'

@Options({
  components: {
    UButton
  },
  emits: ['addNewDoc', 'updateDoc', 'deleteDoc'],
  props: {
    title: String,
    templates: {
      type: Array,
      default: []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getFileUrl(url: string) {
      const mediaURL = process.env.VUE_APP_URL + '/media/'
      return `${mediaURL}${url}`
    },
    updateDoc(item: any) {
      this.$emit('updateDoc', item)
    },
    addNewDoc() {
      this.$emit('addNewDoc')
    },
    deleteDoc(item: any) {
      this.$emit('deleteDoc', item)
    }
  }
})
export default class TemplateItem extends Vue {}
</script>

<style lang="postcss" scoped>
.plus-icon {
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 18px;
  font-size: 18px;
  margin-left: 25px;
  flex-shrink: 0;
}
</style>
