<template>
  <Loader title="Данные загружаются, пожалуйста подождите" v-if="loading" />

  <div class="order-documents">
    <div class="list-table-wrap">
      <UTable colspan="5" v-if="filteredDocuments.length">
        <template #thead>
          <tr>
            <th>Заказ</th>
            <th class="w-[200px]">Исполнитель</th>
            <th class="w-[60px]"></th>
            <th class="w-[320px]" colspan="2">Документы к подписанию</th>
            <th class="w-[100px]"></th>
          </tr>
        </template>
        <template #tbody>
          <tr
            v-for="document in filteredDocuments"
            :key="document.id"
            class="align-top whitespace-nowrap"
            :class="{ 'document-cancelled': document.status === 'cancelled' }"
          >
            <td>
              <span class="block overflow-hidden text-ellipsis" v-tooltip
                ><td>№{{ document.id }} {{ document.name }}</td></span
              >
            </td>
            <td>
              {{ document.worker?.worker_full_name }}
              <p class="heading-gray-3">
                <span class="citizenship">{{ getCitizenship(document.worker?.worker_citizenship) }}</span>
              </p>
            </td>
            <td>
              <div class="flex relative">
                <template v-if="showPassportFieldByCountry('start_work_notification', document.worker?.worker_citizenship)">
                  <a
                    v-if="document.start_work_notification?.file"
                    :href="getDownloadLink(document.start_work_notification.file)"
                    target="_blank"
                    data-tooltip="Приложение №7, cкачается при клике"
                    class="tooltip-item mr-2"
                  >
                    <img src="/icons/order/appdoc.svg" alt="App icon" />
                  </a>
                  <div v-else class="tooltip-item -empty mr-2" data-tooltip="Приложение №7 создается...">
                    <img src="/icons/order/appdoc-empty.svg" alt="App icon" />
                  </div>

                  <a
                    v-if="document.end_work_notification?.file"
                    :href="getDownloadLink(document.end_work_notification.file)"
                    class="tooltip-item"
                    data-tooltip="Приложение №8, cкачается при клике"
                    target="_blank"
                  >
                    <img src="/icons/order/appdoc.svg" alt="App icon" />
                  </a>
                  <div v-else class="tooltip-item -empty" data-tooltip="Приложение №8 создается...">
                    <img src="/icons/order/appdoc-empty.svg" alt="App icon" />
                  </div>
                </template>
              </div>
            </td>
            <td>
              <DocItem
                title="Договор"
                :document="document.agreement"
                :class="{ 'document-cancelled': document.status === 'cancelled' }"
                :orderStatus="order?.status"
                :status="document.agreement_status && document.agreement_status[0] && document.agreement_status[0].status"
              />
              <UISelect
                v-if="document.agreement"
                label="Статус"
                class="form-select-small mt-2"
                unselectedLabel="Cтатус"
                :value="document.agreement?.status"
                :options="docStatuses"
                @change="updateDocStatus($event, document.agreement?.id)"
              />
            </td>
            <td>
              <DocItem
                title="Задание"
                :document="document.task_order"
                :class="{ 'document-cancelled': document.status === 'cancelled' }"
                :orderStatus="order?.status"
                :status="document.task_order_status && document.task_order_status[0] && document.task_order_status[0].status"
              />
              <UISelect
                v-if="document.task_order"
                label="Статус"
                class="form-select-small mt-2"
                unselectedLabel="Cтатус"
                :value="document.task_order?.status"
                :options="docStatuses"
                @change="updateDocStatus($event, document.task_order?.id)"
              />
            </td>
            <td>
              <DocItem
                title="Акт"
                :document="document.acceptance_certificate"
                :class="{ 'document-cancelled': document.status === 'cancelled' }"
                :orderStatus="order?.status"
                :status="
                  document.acceptance_certificate_status &&
                  document.acceptance_certificate_status[0] &&
                  document.acceptance_certificate_status[0].status
                "
              />
              <UISelect
                v-if="document.acceptance_certificate"
                class="form-select-small mt-2"
                unselectedLabel="Cтатус"
                :value="document.acceptance_certificate?.status"
                :options="docStatuses"
                @change="updateDocStatus($event, document.acceptance_certificate?.id)"
              />
            </td>
          </tr>
        </template>
      </UTable>
      <span class="bg-white block p-4" v-else>Нет данных</span>
    </div>
  </div>

  <u-modal title="Расторжение документа" :show="showModal" @cancel="toggleModal" @success="terminateDoc">
    <template #content>
      <h3>Подтвердите ваше действие</h3>
    </template>
    <template #buttons>
      <div class="flex gap-3 justify-between">
        <UButton class="flex-grow" label="Отмена" color="secondary" @click="cancelModal" />
        <UButton class="flex-grow" label="Подтверждаю" @click="terminateDoc" />
      </div>
    </template>
  </u-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { UISelect, UModal, UButton, UTable } from 'unit-uikit'
import DocItem from './DocItem.vue'
import Loader from '@/components/Loader/Loader.vue'

import { API } from '@/utils/API'
import COUNTRIES, { showPassportFieldByCountry } from '@/utils/Countries'
import { EDocsStatus } from '@/types/api-values'
import { DOCS_STATUS } from '@/utils/consts'

@Options({
  name: 'order',
  components: {
    UTable,
    UISelect,
    DocItem,
    Loader,
    UModal,
    UButton
  },
  data() {
    return {
      loading: true,
      showModal: false,
      termitedDocId: null,
      orderId: this.$route.params.id,
      order: null,
      documents: [],
      allDocsSigned: true,
      docStatuses: [
        {
          id: EDocsStatus.signed,
          name: DOCS_STATUS[EDocsStatus.signed]
        },
        {
          id: EDocsStatus.not_signed,
          name: DOCS_STATUS[EDocsStatus.not_signed]
        },
        {
          id: EDocsStatus.active,
          name: DOCS_STATUS[EDocsStatus.active]
        },
        {
          id: EDocsStatus.finished,
          name: DOCS_STATUS[EDocsStatus.finished]
        },
        {
          id: EDocsStatus.terminated,
          name: DOCS_STATUS[EDocsStatus.terminated]
        },
        // {
        //   id: EDocsStatus.exists,
        //   name: DOCS_STATUS[EDocsStatus.exists]
        // },
        {
          id: EDocsStatus.converting_error,
          name: DOCS_STATUS[EDocsStatus.converting_error]
        }
      ]
    }
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal
    },
    cancelModal() {
      this.termitedDocId = null
      this.showModal = false
    },
    terminateDoc() {
      const doc = this.termitedDocId
      if (doc) {
        this.$store
          .dispatch('order/updateSingleDoc', { doc: doc, data: { status: EDocsStatus.terminated } })
          .then(() => {
            this.getDocsInfo()
          })
          .catch((e: any) => {
            console.error(e)
          })
      }
      this.cancelModal()
    },
    updateDocStatus($event: any, doc: any) {
      const status = $event.target.value
      if (status === EDocsStatus.terminated) {
        this.termitedDocId = doc
        this.showModal = true
      } else {
        if (status && doc) {
          return this.$store.dispatch('order/updateSingleDoc', { doc: doc, data: { status } })
        }
      }
    },
    getDocsInfo() {
      this.$store
        .dispatch('order/getOrderDocuments', this.orderId)
        .then((res: any) => {
          this.documents = res
          res.forEach((doc: any) => {
            if (doc.agreement_status && doc.agreement_status[0] && doc.agreement_status[0].status !== 'SIGNED') {
              this.allDocsSigned = false
            }
            if (doc.task_order_status && doc.task_order_status[0] && doc.task_order_status[0].status !== 'SIGNED') {
              this.allDocsSigned = false
            }
            if (
              doc.acceptance_certificate_status &&
              doc.acceptance_certificate_status[0] &&
              doc.acceptance_certificate_status[0].status !== 'SIGNED'
            ) {
              this.allDocsSigned = false
            }
          })
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    showPassportFieldByCountry(field: string, citizenship: any) {
      return showPassportFieldByCountry(field, citizenship)
    },
    getCitizenship(citizenship: any) {
      return COUNTRIES[citizenship]
    },
    getDownloadLink(file: string) {
      if (file) {
        return API.GET_FILE(file)
      }
      return ''
    }
  },
  created() {
    if (this.orderId) {
      this.loading = true

      this.$store
        .dispatch('order/getSingleOrder', this.orderId)
        .then((response: any) => {
          this.order = response
          this.loading = false
        })
        .catch((e: any) => {
          console.error(e)
          this.loading = false
        })

      this.getDocsInfo()
    }
  },
  computed: {
    filteredDocuments() {
      const documents = this.documents
      return documents.sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
    }
  }
})
export default class OrderDocs extends Vue {}
</script>

<style lang="scss" scoped>
.order-documents {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

:deep(.documents-link) {
  white-space: nowrap;
  text-decoration: none;
  color: #9c9c9c !important;

  &:hover {
    cursor: not-allowed;
  }
}

:deep(.document) {
  &__not-signed {
    .documents-link {
      color: black;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .copy-link-btn {
      background: #5476e3;
      border-radius: 4px;
      color: white;
      height: 32px;
      line-height: 32px;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: 10px;

      &:hover {
        background-color: #4163cf;
      }
    }
  }

  &__signed {
    .documents-link {
      color: #2cb63a;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .copy-link-btn {
      margin-left: 10px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.fixed-cell {
  padding-right: 0;
}

:deep(.document-signed-icon) {
  width: 20px;
  margin-left: -20px;
  vertical-align: top;
  margin-right: 4px;
}

.document-cancelled {
  color: #e1e1e1 !important;
  span,
  td,
  div,
  a {
    color: #e1e1e1 !important;
  }

  :deep(.documents-link) {
    color: #e1e1e1 !important;
  }

  :deep(.copy-link-btn) {
    display: none;
  }
}
.tooltip-item {
  &:after {
    opacity: 0;
    padding: 2px;
    visibility: hidden;
    position: absolute;
    content: attr(data-tooltip);
    padding: 8px;
    bottom: 100%;
    right: 3px;
    z-index: 300 !important;
    width: 200px;

    white-space: normal;
    color: black;
    min-width: 160px;
    z-index: 2;
    border-radius: 2px;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    border: 1px solid #000000;
    border-radius: 4px;
    transform: translate(10px, -5px);

    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
  }
  &:hover {
    &:after {
      display: block;
      opacity: 1;
      z-index: 300 !important;
      background: white;
      visibility: visible;
    }
  }

  &.-empty:after {
    padding: 8px;
    border-color: #c6c6c6;
    font-weight: 400;
    color: #999999;
    width: 140px;
    text-align: center;
    right: 30px;
  }
}

.citizenship {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: black;
}

td,
th {
  text-overflow: ellipsis;
  padding-left: 6px;
  padding-right: 6px;
}

table {
  table-layout: fixed;
}
</style>
