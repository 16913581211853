<script setup lang="ts">
import { ref, Ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { EUserRoles } from '@/types'

import { UInput, UTable, UAlert, UButton, UISelect, UDateRange, UModal, UTextarea } from 'unit-uikit'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'

import { formatPrice } from '@/utils/helpers'

import { EBillsStatus, BILLS_STATUS_LABELS, TBills } from '@/interfaces/main'

const store = useStore()
const authStore = useAuthStore()
const { userRole } = storeToRefs(authStore)

const loading = ref(false)

const bills = ref([]) as Ref<TBills[]>

const billNumber = ref('')
const billAmount = ref('')
const startDate = ref('')
const endDate = ref('')
const selectedStatus = ref('')
const newBillStatus = ref('')
const selectedObj = ref('')
const selectedCompany = ref('')

let objectsListOptions: any = ref([])
let companiesListOptions: any = ref([])

const allObjects = ref([])
const allCompanies = ref([])

const isChangeBillStatus = ref(false)
const showModal = ref(false)

const statusListOptions = [
  {
    id: 'all',
    name: 'Все'
  },
  {
    id: EBillsStatus.paid_by_bank_transfer,
    name: BILLS_STATUS_LABELS[EBillsStatus.paid_by_bank_transfer]
  },
  {
    id: EBillsStatus.deleted,
    name: BILLS_STATUS_LABELS[EBillsStatus.deleted]
  },
  {
    id: EBillsStatus.not_paid,
    name: BILLS_STATUS_LABELS[EBillsStatus.not_paid]
  }
]

const scrollData = ref({
  count: 0,
  requestCount: 0,
  size: 20,
  page: 1
})

onMounted(() => {
  getBills(false)

  store
    .dispatch('main/getObject')
    .then((res: any) => {
      const objects = res
      allObjects.value = res

      objectsListOptions.value = [
        {
          id: 'allObjects',
          name: 'Выбрать все'
        },
        ...objects
      ]
    })
    .catch((e: any) => {
      console.error(e)
    })

  store
    .dispatch('companies/getCompaniesList', '')
    .then((res: any) => {
      const companies = res.filter((company: any) => company.name)
      allCompanies.value = res

      companiesListOptions.value = [
        {
          id: 'allCompanies',
          name: 'Выбрать все'
        },
        ...companies
      ]
    })
    .catch((e) => {
      console.error(e)
    })
})

const formatDateForQueryString = (date: any) => {
  const formatedDate = date.split('.')
  return `${formatedDate[0]}-${formatedDate[1]}-${formatedDate[2]}`
}

let queryParams = ref('')

const clearFilters = () => {
  billNumber.value = ''
  selectedObj.value = ''
  selectedCompany.value = ''
  startDate.value = ''
  endDate.value = ''
  billAmount.value = ''
  selectedStatus.value = ''

  const startDateInput = document.getElementById('startDate') as HTMLInputElement
  const endDateInput = document.getElementById('endDate') as HTMLInputElement
  startDateInput.value = ''
  endDateInput.value = ''

  queryParams.value = ''
  getBills(false)
}

const filterQueryData = () => {
  if (billNumber.value) {
    queryParams.value += `&number=${billNumber.value}`

    // if (bills.value.length && !(bills.value.length < scrollData.value.requestCount)) {
    //   return
    // }
  }

  if (selectedObj.value) {
    selectedObj.value === 'allObjects' ? (queryParams.value += '') : (queryParams.value += `&object_id=${selectedObj.value}`)

    // if (bills.value.length && !(bills.value.length < scrollData.value.requestCount)) {
    //   return
    // }
  }

  if (selectedCompany.value) {
    selectedCompany.value === 'allCompanies' ? (queryParams.value += '') : (queryParams.value += `&company_id=${selectedCompany.value}`)

    // if (bills.value.length && !(bills.value.length < scrollData.value.requestCount)) {
    //   return
    // }
  }

  if (startDate.value) {
    const formattedDate = moment(startDate.value).format('YYYY.MM.DD')
    queryParams.value += `&start_date=${formatDateForQueryString(formattedDate)}`

    // if (bills.value.length && !(bills.value.length < scrollData.value.requestCount)) {
    //   return
    // }
  }

  if (endDate.value) {
    const formattedDate = moment(endDate.value).format('YYYY.MM.DD')
    queryParams.value += `&end_date=${formatDateForQueryString(formattedDate)}`

    // if (bills.value.length && !(bills.value.length < scrollData.value.requestCount)) {
    //   return
    // }
  }

  if (billAmount.value) {
    queryParams.value += `&amount=${billAmount.value}`

    // if (bills.value.length && !(bills.value.length < scrollData.value.requestCount)) {
    //   return
    // }
  }

  if (selectedStatus.value) {
    selectedStatus.value === 'all' ? (queryParams.value += '') : (queryParams.value += `&status=${selectedStatus.value}`)

    // if (bills.value.length && !(bills.value.length < scrollData.value.requestCount)) {
    //   return
    // }
  }
}

const getBills = (isFilter: boolean) => {
  loading.value = true

  store
    .dispatch('bills/getBills', `?page=${scrollData.value.page}&count=${scrollData.value.size}${isFilter ? queryParams.value : ''}`)
    .then((response) => {
      scrollData.value.count = response.count
      bills.value = response.results || []
      loading.value = false
    })
    .catch((e: any) => {
      console.error(e)
      loading.value = false
    })
}

const clearData = () => {
  scrollData.value.page = 1
  scrollData.value.count = 1
  bills.value = []
}

const filterBills = () => {
  queryParams.value = ''
  filterQueryData()
  clearData()
  getBills(true)
}

const loadBills = async () => {
  if (bills.value.length < scrollData.value.count) {
    // filterQueryData()
    scrollData.value.page++
    const queryString = `?page=${scrollData.value.page}&count=${scrollData.value.size}` + queryParams.value

    const response = await store.dispatch('bills/getBills', queryString)

    const data = response
    if (data.results && data.count) {
      scrollData.value.requestCount = data.count
      const result = data.results
      bills.value.push(...result)
    }
  }
}

// const deleteBill = (id: string | number) => {
//   axios
//     .delete(API.DELETE_BILL(id))
//     .then(() => {
//       clearData()
//       getBills(false)
//     })
//     .catch((e) => {
//       console.error(e)
//     })
// }

// const closeEditMenu = () => {
//   bills.value = bills.value.map((item: any) => {
//     item.showEditMenu = false
//     return item
//   })
// }

// const openEditMenu = (id: string | number) => {
//   bills.value = bills.value.map((item: any) => {
//     const itemId = item.id
//     if (id + '' === itemId + '') {
//       item.showEditMenu = !item.showEditMenu
//     } else {
//       item.showEditMenu = false
//     }
//     return item
//   })
// }

const formatDate = (date: any) => {
  if (date) {
    return moment(new Date(date)).format('DD.MM.YYYY')
  }
  return ''
}

const getAlertType = (status: EBillsStatus) => {
  switch (status) {
    case EBillsStatus.not_paid:
      return 'error'
    case EBillsStatus.paid_by_bank_transfer:
      return 'success'
    case EBillsStatus.deleted:
      return 'info'
    default:
      return 'info'
  }
}

const generateBillInProgress = ref(false)

const getAccountFile = (id: string | number) => {
  generateBillInProgress.value = true

  store
    .dispatch('bills/getAccountFile', id)
    .then((res) => {
      bills.value = bills.value.map((item: any) => {
        const itemId = item.id
        if (id + '' === itemId + '') {
          item.account_file = res.data.file
        }
        generateBillInProgress.value = false
        return item
      })
    })
    .catch((e: any) => {
      console.error(e)
    })
}

const currentID: any = ref('')

const changeBillStatus = (id: string | number) => {
  currentID.value = id
  if (currentID.value === id) {
    isChangeBillStatus.value = true
  }
}

let billToChangeData: any = ref([])

const approveBillStatus = (status: string, bill: TBills) => {
  if (status === Object.keys(BILLS_STATUS_LABELS)[0]) {
    showModal.value = true
    billToChangeData = bill
  } else {
    showModal.value = false
    store
      .dispatch('bills/updateReplenishmentAccount', { id: bill.id, data: { status: newBillStatus.value } })
      .then(() => {
        isChangeBillStatus.value = false
        newBillStatus.value = ''
        clearData()
        getBills(false)
      })
      .catch((e: any) => {
        console.error(e)
      })
  }
}

const cancelNewStatus = () => {
  showModal.value = false
  newBillStatus.value = ''
}

const approveNewStatus = (id: string | number, purposeOfPayment: string, amount: string) => {
  store
    .dispatch('bills/updateReplenishmentAccount', {
      id: id,
      data: { purpose_of_payment: purposeOfPayment, amount: amount, status: newBillStatus.value }
    })
    .then(() => {
      isChangeBillStatus.value = false
      newBillStatus.value = ''
      showModal.value = false
      clearData()
      getBills(false)
    })
    .catch((e: any) => {
      console.error(e)
      showModal.value = false
    })
}
</script>
<template>
  <!-- <div class="close-edit-menu hidden" @click="closeEditMenu"></div> -->

  <Loader v-if="loading || generateBillInProgress" />
  <div class="flex flex-wrap gap-3">
    <UInput placeholder="№ счета" :value="billNumber" v-model="billNumber" />
    <UISelect
      class="!w-[262px]"
      label="Наименование объекта"
      unselectedLabel="Выберите объект"
      placeholder="Объект"
      :options="objectsListOptions"
      v-model="selectedObj"
      :value="selectedObj"
    />
    <UISelect
      class="!w-[262px]"
      label="Название компании"
      unselectedLabel="Выберите компанию"
      placeholder="Компания"
      :options="companiesListOptions"
      v-model="selectedCompany"
      :value="selectedCompany"
    />
    <UDateRange
      class="!w-[210px] !max-w-[210px]"
      :start="startDate"
      :finish="endDate"
      @update-start-date="(date: string) => { startDate = date }"
      @update-finish-date="(date: string) => { endDate = date }"
      title="Дата выставления счета"
      startPlaceHolder="От"
      endPlaceHolder="До"
    />
    <UInput placeholder="Сумма" :value="billAmount" v-model="billAmount" />
    <UISelect
      label="Статус"
      unselectedLabel="Выберите статус"
      placeholder="Наименование"
      :options="statusListOptions"
      v-model="selectedStatus"
      :value="selectedStatus"
    />
    <div class="flex">
      <UButton class="mr-3" size="sm" label="Поиск" @click="filterBills" />
      <UButton color="secondary" size="sm" label="✖" @click="clearFilters" />
    </div>
  </div>
  <UTable v-if="!loading" colspan="7" :callback="loadBills">
    <template #thead>
      <tr>
        <th class="w-[140px]">№ счета</th>
        <th class="w-[260px]">Объект</th>
        <th class="w-[180px]">Компания</th>
        <th class="w-[110px]">Дата</th>
        <th class="w-[120px]">Сумма</th>
        <th class="w-[180px]">Статус</th>
        <th class="w-[160px]"></th>
        <!-- <th class="w-[60px]"></th> -->
      </tr>
    </template>
    <template #tbody>
      <tr class="whitespace-nowrap" v-for="item in bills" :key="item.id">
        <td>
          <span :class="{ 'opacity-30': item.status === Object.keys(BILLS_STATUS_LABELS)[2] }">{{ item.payment_number }}</span>
        </td>
        <td>
          <p
            :class="{ 'opacity-30': item.status === Object.keys(BILLS_STATUS_LABELS)[2] }"
            class="overflow-hidden text-ellipsis"
            v-tooltip="`${item.objects_list[0]?.name}`"
          >
            {{ item.objects_list[0]?.name }}
          </p>
        </td>
        <td>
          <p
            :class="{ 'opacity-30': item.status === Object.keys(BILLS_STATUS_LABELS)[2] }"
            class="overflow-hidden text-ellipsis"
            v-tooltip="`${item.company?.name}`"
          >
            {{ item.company?.name }}
          </p>
        </td>
        <td>
          <span :class="{ 'opacity-30': item.status === Object.keys(BILLS_STATUS_LABELS)[2] }">{{ formatDate(item.date) }}</span>
        </td>
        <td>
          <span :class="{ 'opacity-30': item.status === Object.keys(BILLS_STATUS_LABELS)[2] }">{{ formatPrice(item.amount) }}&nbsp;₽</span>
        </td>
        <td>
          <UISelect
            v-if="isChangeBillStatus && currentID === item.id && item.status !== Object.keys(BILLS_STATUS_LABELS)[0]"
            @change="approveBillStatus(newBillStatus, item)"
            label="Статус"
            unselectedLabel="Выберите статус"
            placeholder="Наименование"
            :options="statusListOptions.slice(1)"
            v-model="newBillStatus"
            :value="newBillStatus"
          />
          <UAlert
            :class="{
              'opacity-30': item.status === Object.keys(BILLS_STATUS_LABELS)[2],
              'hover:opacity-60 hover:cursor-pointer': item.status !== Object.keys(BILLS_STATUS_LABELS)[0]
            }"
            v-else
            @click="userRole === EUserRoles.support ? () => {} : changeBillStatus(item.id)"
            :type="getAlertType(item.status)"
            :label="BILLS_STATUS_LABELS[item.status]"
          />
        </td>
        <td>
          <a
            v-if="item.account_file"
            :class="{ 'opacity-30': item.status === Object.keys(BILLS_STATUS_LABELS)[2] }"
            class="text-accent cursor-pointer"
            target="_blank"
            download
            :href="item.account_file"
            >Скачать счет</a
          >
          <a
            v-else
            @click="getAccountFile(item.id)"
            :class="{ 'opacity-30': item.status === Object.keys(BILLS_STATUS_LABELS)[2] }"
            class="text-accent cursor-pointer"
            target="_blank"
            download
            :href="item.account_file"
            >{{ item.account_file ? 'Скачать счет' : 'Сгенерировать счет' }}</a
          >
        </td>
        <!-- <td class="!overflow-visible">
          <button
            v-if="item.status !== Object.keys(BILLS_STATUS_LABELS)[0]"
            type="button"
            class="edit-btn relative"
            @click="openEditMenu(item.id)"
          >
            <img class="" src="/icons/extra.svg" alt="Редактировать" />
            <div v-if="item.showEditMenu" class="edit-menu flex flex-col">
              <button @click="deleteBill(item.id)" type="button" class="btn-transparent">Удалить</button>
            </div>
          </button>
        </td> -->
      </tr>
      <UModal :show="showModal" :title="billToChangeData.company?.name" @success="showModal = false" @cancel="cancelNewStatus()">
        <template #content>
          <UTextarea
            label="Обоснование пополнения"
            placeholder="Назначение платежа"
            :value="billToChangeData.purpose_of_payment"
            v-model="billToChangeData.purpose_of_payment"
          />
          <UInput label="Сумма пополнения" placeholder="Сумма" :value="billToChangeData.amount" v-model="billToChangeData.amount" />
        </template>
        <template #buttons>
          <div class="flex justify-between">
            <UButton class="!w-full mr-3" @click="cancelNewStatus()" label="Отмена" color="secondary" />
            <UButton
              class="!w-full"
              @click="approveNewStatus(billToChangeData.id, billToChangeData.purpose_of_payment, billToChangeData.amount)"
              label="Подтвердить"
            />
          </div>
        </template>
      </UModal>
    </template>
  </UTable>
</template>
<style lang="postcss" scoped>
:deep(table) {
  @apply table-fixed;
}

td {
  word-break: break-word;
  @apply overflow-hidden text-ellipsis text-black max-w-[404px];
}
.edit-menu {
  @apply top-full right-2/4;
  transform: translate(0);
  & .btn-transparent {
    @apply min-w-max;
    padding-right: 15px;
  }
}

:deep(.modal h3) {
  @apply whitespace-normal;
}

:deep(.modal) {
  @apply w-[560px];
}
</style>
