<script lang="ts" setup>
import { ref, computed } from 'vue'
import { USidebar } from 'unit-uikit'
import { useRoute, useRouter } from 'vue-router'
import { EUserRoles } from '@/types'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { useStore } from 'vuex'

const store = useStore()
const authStore = useAuthStore()
const { userRole } = storeToRefs(authStore)
const route = useRoute()
const router = useRouter()

const supportFio = ref('')
const supportEmail = ref('')

function normalizeUserFrom(data: any) {
  if (data) {
    return {
      fio: `${data.last_name || ''} ${data.first_name || ''} ${data.patronymic_name || ''}`,
      email: data.email || ''
    }
  }
  return {
    fio: '',
    email: ''
  }
}

const navigations = [
  {
    icon: 'icon-performerce',
    title: 'Исполнители',
    route: 'workers.verified'
  },
  {
    icon: 'icon-company',
    title: 'Компании',
    route: 'companies.verified',
    subroute: {
      title: 'Сотрудники',
      route: 'staff.verified'
    }
  },
  // {
  //   icon: '/icons/disputes_black.svg',
  //   title: 'Споры',
  //   route: 'disputes'
  // },
  // {
  //   icon: '/icons/headset_black.svg',
  //   alt: 'Docs',
  //   title: 'Поддержка',
  //   route: 'support'
  // },
  {
    icon: 'icon-orders',
    title: 'Заказы',
    route: 'orders.list'
  },
  {
    icon: 'icon-finance',
    title: 'Финансы',
    route: 'reportings.add',
    subroute: {
      title: 'Депозиты',
      route: 'deposit.list'
    }
  }
]

const showSubroute = (item: any) => {
  const name = route?.name as string
  switch (item.route) {
    case 'companies.verified':
      return name?.includes('companies') || name?.includes('staff')
    case 'reportings.add':
      return name?.includes('deposit') || name?.includes('reportings')
    default:
      return false
  }
}

const logout = () => {
  store.dispatch('auth/logout')
  router.push('/login')
}

const position = computed(() => {
  if (userRole.value === EUserRoles.support) {
    return 'Саппорт'
  }
  if (userRole.value === EUserRoles.admin) {
    return 'Админ'
  }
  return ''
})

store
  .dispatch('main/getUserInfo')
  .then((res: any) => {
    const data = res
    if (data) {
      const { fio, email } = normalizeUserFrom(data)
      supportFio.value = fio
      supportEmail.value = email
    }
  })
  .catch((e: any) => {
    console.error(e)
  })
</script>

<template name="navbar">
  <USidebar :navigations="navigations" :show-subroute="showSubroute" @logout="logout">
    <template #menu-logo>
      <div class="menu-switcher">
        <img src="/icons/logo.svg" alt="Logo UNIT" class="app-logo" />
      </div>
    </template>
    <template #menu-content>
      <div class="mt-auto company-zone flex flex-col">
        <div class="mb-3">
          <p class="text-sm leading-snug">{{ position }}</p>
          <p class="text-sm leading-snug font-medium">{{ supportFio }}</p>
          <p class="text-sm leading-snug font-medium">{{ supportEmail }}</p>
        </div>
      </div>
    </template>
  </USidebar>
</template>

<style lang="postcss">
.menu-switcher {
  @apply flex items-center flex-grow relative mr-5;
  height: 30px;
  padding-left: 24px;

  &:hover {
    cursor: pointer;
  }
}

.menu-switcher-options {
  @apply absolute bg-white;
  z-index: 1;
  top: calc(100% + 10px);
  right: 0;
  left: 24px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 10px;
}

.app-logo {
  height: 30px;
  vertical-align: top;
}

.main-navigation.toggled .menu-switcher {
  height: 40px;
  padding: 0;
  margin: 0;
  overflow: hidden;

  .app-logo {
    height: 38px;
    max-width: none;
  }
}
</style>
